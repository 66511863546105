import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  assetLibraryEntryPaginatedListSchema,
  assetLibraryFolderListSchema,
  assetLibraryFolderSchema,
  TAssetLibraryFolderQuery,
  TAssetLibraryQuery,
  TCreateAssetLibraryEntry,
  TCreateAssetLibraryFolder,
  TUpdateAssetLibraryEntry,
  TUpdateAssetLibraryFolder,
} from '@valuecase/common'
import { useEffect, useMemo, useState } from 'react'
import { useSpacesApi } from '../../spaces-api'

export const useMutateAssetLibraryEntry = () => {
  const { spacesApi } = useSpacesApi()
  const queryClient = useQueryClient()

  const createMutation = useMutation({
    mutationFn: (data: TCreateAssetLibraryEntry) => spacesApi.post(`/asset-library`, data),
    onSuccess: () => queryClient.invalidateQueries(['asset-library']),
  })

  const updateMutation = useMutation({
    mutationFn: (data: TUpdateAssetLibraryEntry & { id: string }) =>
      spacesApi.patch(`/asset-library/${data.id}`, data),
    onSuccess: () => queryClient.invalidateQueries(['asset-library']),
  })

  const deleteMutation = useMutation({
    mutationFn: (id: string) => spacesApi.delete(`/asset-library/${id}`),
    onSuccess: () => queryClient.invalidateQueries(['asset-library']),
  })

  return {
    createAsset: createMutation.mutateAsync,
    updateAsset: updateMutation.mutateAsync,
    deleteAsset: deleteMutation.mutateAsync,
  }
}

export const useAssetLibraryItems = ({ ...options }: TAssetLibraryQuery) => {
  const { spacesApi } = useSpacesApi()
  const queryKey = useMemo(() => {
    return ['asset-library', options]
  }, [options])

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const { itemsPerPage, page, search, types, mimeTypes, folderId } = options
      const url = new URL(window.location.href)
      url.searchParams.set('itemsPerPage', (itemsPerPage ?? 10).toString())
      url.searchParams.set('page', (page ?? 1).toString())

      if (search) {
        url.searchParams.set('search', search)
      }

      if (types) {
        for (const type of types) {
          url.searchParams.append('types[]', type)
        }
      }

      if (mimeTypes) {
        for (const mimeType of mimeTypes) {
          url.searchParams.append('mimeTypes[]', mimeType)
        }
      }

      if (folderId !== undefined) {
        url.searchParams.set('folderId', folderId ?? 'null')
      }

      const response = await spacesApi.get(`/asset-library${url.search}`)

      return assetLibraryEntryPaginatedListSchema.parse(response.data)
    },
    staleTime: 1000 * 60, // 60 seconds
  })

  // we need the useState here that React doesn't re-render the component when the query is refetched
  const [totalItems, setTotalItems] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [itemsExist, setItemsExist] = useState(false)

  useEffect(() => {
    if (query.data) {
      setTotalItems(query.data.totalItems)
      setTotalPages(query.data.totalPages)
      setItemsExist(query.data.itemsExist)
    }
  }, [query.data])

  return {
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    error: query.error,
    isFetched: query.isFetched,

    items: query.data?.items ?? [],
    totalPages,
    totalItems,
    itemsExist,

    ...useMutateAssetLibraryEntry(),
  }
}

export const useMutateAssetLibraryFolder = () => {
  const { spacesApi } = useSpacesApi()
  const queryClient = useQueryClient()

  const createFolder = useMutation({
    mutationFn: (data: TCreateAssetLibraryFolder) => spacesApi.post(`/asset-library-folders`, data),
    onSuccess: () => queryClient.invalidateQueries(['asset-library-folders']),
  })

  const updateFolder = useMutation({
    mutationFn: (data: TUpdateAssetLibraryFolder & { id: string }) =>
      spacesApi.patch(`/asset-library-folders/${data.id}`, data),
    onSuccess: () => queryClient.invalidateQueries(['asset-library-folders']),
  })

  const deleteFolder = useMutation({
    mutationFn: (id: string) => spacesApi.delete(`/asset-library-folders/${id}`),
    onSuccess: () => queryClient.invalidateQueries(['asset-library-folders']),
  })

  return {
    createFolder: createFolder.mutateAsync,
    updateFolder: updateFolder.mutateAsync,
    deleteFolder: deleteFolder.mutateAsync,
  }
}

export const useAssetLibraryFolder = (folderId?: string) => {
  const { spacesApi } = useSpacesApi()

  return useQuery({
    queryKey: ['asset-library-folder', folderId],
    queryFn: async () => {
      const response = await spacesApi.get(`/asset-library-folders/${folderId}`)
      return assetLibraryFolderSchema.parse(response.data)
    },
    enabled: !!folderId,
    retry: false,
    staleTime: 1000 * 60, // 60 seconds
  })
}

export const useAssetLibraryFolders = ({ parentFolderId }: TAssetLibraryFolderQuery) => {
  const { spacesApi } = useSpacesApi()

  const queryClient = useQueryClient()

  return useQuery({
    queryKey: ['asset-library-folders', parentFolderId],
    queryFn: async () => {
      const url = new URL(window.location.href)

      if (parentFolderId !== undefined) {
        url.searchParams.set('parentFolderId', parentFolderId ?? 'null')
      }

      const response = await spacesApi.get(`/asset-library-folders${url.search}`)
      const data = assetLibraryFolderListSchema.parse(response.data)

      for (const folder of data) {
        queryClient.setQueryData(['asset-library-folder', folder.id], folder)
      }

      return data
    },
    staleTime: 1000 * 60, // 60 seconds
  })
}
