import { useState } from 'react'
import { Button } from '@valuecase/ui-components'
import { CreateTenantApiResponse, CreateTenantApiBody } from '@valuecase/seller-backend'
import SellerApi from '../../api/SellerApi'

const createTenant = (tenantData: CreateTenantApiBody) =>
  SellerApi.post<CreateTenantApiResponse, CreateTenantApiBody, undefined>('/tenants', tenantData)

const CreateTenantComponent = () => {
  const [newTenantData, setNewTenantData] = useState(`{
    "subdomain": "test-sub",
    "customDomain": "www.example.com",
    "theme": "Dark",
    "primaryColor": "#003366",
    "tenantName": "Test Tenant",
    "firstUser": {
      "email": "",
      "firstName": "",
      "lastName": ""
    },
    "seatAllowance": null,
    "onboardingSharedPageUrl": null
  }`)
  const [output, setOutput] = useState('')
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        const doCreateTenant = async () => {
          try {
            const tenantData = JSON.parse(newTenantData) as CreateTenantApiBody
            const res = await createTenant(tenantData)
            setOutput(JSON.stringify(res.payload, null, 2))
          } catch (err: any) {
            setOutput('Error (see console)')
            console.error(err?.response?.data ? JSON.stringify(err?.response?.data, null, 4) : err)
          }
        }
        doCreateTenant()
      }}
    >
      <h2>Create new tenant</h2>
      <textarea
        style={{
          width: '100%',
        }}
        value={newTenantData}
        rows={10}
        onChange={(event) => setNewTenantData(event.target.value)}
      ></textarea>
      <Button label='Create tenant' submitButton={true} />
      <div>
        <pre>{output}</pre>
      </div>
      <div>
        <div>
          <strong>Validation rules</strong>
        </div>
        <div>
          <pre>
            {`
  subdomain: z.string().regex(/^[a-z0-9\\-]+$/).max(200),
  customDomain: fullyQualifiedDomainNameSchema.optional(),
  theme: z.string().regex(/^Light|Dark$/),
  primaryColor: z.string().regex(/^#[a-fA-F0-9]{6}$/),
  tenantName: z.string().min(1).max(200),
  imprint: z.string().optional(),
  privacyPolicy: z.string().optional(),
  logoImage: z.object({
    source: z.string().regex(/^S3$/),
    s3Key: z.string().min(1).max(255),
  }).optional(),
  backgroundImage: z.object({
    source: z.string().regex(/^S3$/),
    s3Key: z.string().min(1).max(255),
  }).optional(),
  firstUserEmail: z.string().email(),
  seatAllowance: z.number().nullish(),
  onboardingSharedPageUrl: z.string().nullish()
            `}
          </pre>
        </div>
      </div>
    </form>
  )
}

export default CreateTenantComponent
