import {
  ColumnDef,
  PaginationState,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  ColumnFiltersState,
  ColumnFilter,
} from '@tanstack/react-table'
import { S } from './ReusableTable.styled'
import { DataTablePagination, Input2, IonIcon } from '@valuecase/ui-components'
import { useState, useMemo, useEffect, MutableRefObject, ReactNode } from 'react'
import { useTheme } from 'styled-components'

export type SetFilterFn = (filter: ColumnFilter) => void
export type SetMultipleFilterFn = (filters: ColumnFilter[]) => void
export type RemoveFilterFn = (columnAccessor: string) => void
export interface ReusableTableControls {
  setFilter: SetFilterFn
  setMultipleFilters: SetMultipleFilterFn
  removeFilter: RemoveFilterFn
}

interface ReusableTableProps<TData> {
  maxWidth: number
  maxTableHeight?: number
  filterBasedOnColumnId: string
  defaultSortedBasedOnColumnId?: string
  data: TData[]
  columns: ColumnDef<TData, any>[]
  onRowClick?: (row: Row<any>) => void
  additionalSearchRowElements?: ReactNode
  controls?: MutableRefObject<ReusableTableControls | null>
  hideColumns?: string[]
  searchPlaceholder?: string
  transparentBackground?: boolean
  showOuterBorder?: boolean
  defaultSortDirection?: 'asc' | 'desc'
  pageSize?: number
  enablePagination?: boolean
  onFinishTypingInSearchInput?: () => void
  rowHeight?: number
  onPaginationClickCb?: (newPageNumber: number) => void
  searchTerm?: string
  onUpdateSearchTerm?: (searchTerm: string) => void
  searchResultsStatus?: 'loading' | 'error' | 'idle'
  searchResultsStatusErrorMessage?: string
}

/**
 * @deprecated use DataTable instead
 */
export default function ReusableTable<TData>({
  maxWidth,
  maxTableHeight,
  data,
  columns,
  filterBasedOnColumnId,
  onRowClick,
  defaultSortedBasedOnColumnId,
  defaultSortDirection,
  additionalSearchRowElements,
  controls,
  hideColumns,
  searchPlaceholder,
  transparentBackground,
  showOuterBorder,
  pageSize: pageSizeParam,
  enablePagination,
  onFinishTypingInSearchInput,
  onPaginationClickCb,
  rowHeight,
  searchTerm,
  onUpdateSearchTerm,
  searchResultsStatus,
  searchResultsStatusErrorMessage,
}: ReusableTableProps<TData>) {
  const defaultData: Array<TData> = []
  const theme = useTheme()

  //needed by react-table for filtering
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const columnVisibility = hideColumns
    ? Object.fromEntries(hideColumns.map((id) => [id, false]))
    : {}

  //needed by react-table for soritng
  const [sorting, setSorting] = useState<SortingState>(
    defaultSortedBasedOnColumnId !== undefined
      ? [
          {
            id: defaultSortedBasedOnColumnId,
            desc: defaultSortDirection === 'asc' ? false : true,
          },
        ]
      : [],
  )

  //needed for pagination
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: typeof pageSizeParam === 'number' ? pageSizeParam : 8,
  })
  const paginationState = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )

  //table instance
  const table = useReactTable<TData>({
    data: data ?? defaultData, //comes as prop
    columns, //comes as prop
    enableFilters: true,
    enableColumnFilters: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: !enablePagination,
    state: {
      sorting,
      columnFilters,
      pagination: paginationState,
      columnVisibility,
    },
    onPaginationChange: enablePagination ? setPagination : undefined,
    getPaginationRowModel: enablePagination ? getPaginationRowModel() : undefined,
    columnResizeMode: 'onChange',
  })
  //Filtering
  //tell react-table to filter based on the spaceName Column
  const [field] = useState(filterBasedOnColumnId)
  const [internalSearchValue, setInternalSearchValue] = useState('')
  //setFilterValue inside react-table
  useEffect(() => {
    // The filtering is being managed by parent component, nothing to do in this case
    if (onUpdateSearchTerm) {
      return
    }
    const column = table.getAllColumns().filter((col) => col.id === field)[0]
    column.setFilterValue(internalSearchValue)
  }, [field, onUpdateSearchTerm, internalSearchValue, table])

  //if passed as a prop, trigger callback when the user finishes typing in the searchInput
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (internalSearchValue.toString().trim().length > 0 && onFinishTypingInSearchInput) {
        onFinishTypingInSearchInput()
      }
    }, 500)

    return () => clearTimeout(timeout)
  }, [internalSearchValue, onFinishTypingInSearchInput])

  useEffect(() => {
    if (!controls) {
      return
    }
    controls.current = {
      setFilter: ({ id: columnAccessor, value }) => {
        const index = columnFilters.findIndex(({ id }) => id === columnAccessor)
        if (index > -1) {
          if (columnFilters[index].value === value) return
          setColumnFilters(
            columnFilters.map((filterEntry) => {
              if (filterEntry.id === columnAccessor) {
                return { id: filterEntry.id, value }
              }
              return filterEntry
            }),
          )
        } else {
          setColumnFilters([...columnFilters, { id: columnAccessor, value }])
        }
      },
      removeFilter: (columnAccessor) => {
        setColumnFilters(columnFilters.filter(({ id }) => id !== columnAccessor))
      },
      setMultipleFilters: (filters) => {
        const untouchedExistingFilters = columnFilters.filter(
          ({ id }) => !filters.find((filter) => filter.id === id),
        )
        setColumnFilters(
          [...untouchedExistingFilters, ...filters].filter(({ value }) => value !== undefined),
        )
      },
    }
  }, [controls, setColumnFilters, columnFilters])

  // Size of first column is the max width of the table, minus 2 x 1 pixel borders, minus
  // the width of all the other columns.
  const maxWidthFirstCol =
    maxWidth - 2 - columns.reduce((prev, current) => prev + (current.size || 0), 0)

  const hasRows = !!table.getRowModel().rows.length

  if (controls && !controls.current) return <></> //Wait until reactTable applies filter then render
  return (
    <S.TableOuterAreaWrapper>
      <S.FiltersAndTableWrapper>
        <S.FilterContainer>
          <S.FilterInputContainer>
            <Input2
              leadingIcon='search-outline'
              placeholder={searchPlaceholder}
              value={searchTerm ?? internalSearchValue}
              onInput={(newVal) => {
                // If search managed by parent component, use parent's callback
                if (onUpdateSearchTerm) {
                  onUpdateSearchTerm(newVal.currentTarget.value)
                } else {
                  // Otherwise perform search locally
                  setInternalSearchValue(newVal.currentTarget.value)
                }
              }}
            />
          </S.FilterInputContainer>
          {additionalSearchRowElements && (
            <S.SearchRowElementsContainer>
              {additionalSearchRowElements}
            </S.SearchRowElementsContainer>
          )}
        </S.FilterContainer>
        {!hasRows && (
          <S.EmptySearchResultWrapper $maxHeight={maxTableHeight}>
            <div>
              <S.EmptySearchResultIconWrapper>
                <IonIcon
                  name='search'
                  style={{ width: '48px', height: '48px', color: theme.grey.s3 }}
                />
              </S.EmptySearchResultIconWrapper>
              <S.EmptySearchResultHeading>
                Sorry we couldn’t find any matches.
              </S.EmptySearchResultHeading>
              <S.EmptySearchResultSubHeading>
                Please try searching with another term.
              </S.EmptySearchResultSubHeading>
            </div>
          </S.EmptySearchResultWrapper>
        )}
        {hasRows && (
          <S.TableWrapper
            style={
              maxTableHeight
                ? {
                    maxHeight: `${maxTableHeight}px`,
                    overflowY: 'auto',
                  }
                : undefined
            }
            $transparentBackground={transparentBackground}
            $showOuterBorder={showOuterBorder}
          >
            <S.Table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, i) => {
                      return (
                        <S.Th
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                            style:
                              i === 0
                                ? {
                                    width: header.getSize(),
                                  }
                                : undefined,
                          }}
                          key={header.id}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </S.Th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <S.Tr
                    $isClickable={!!onRowClick}
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick(row)
                      }
                    }}
                    key={row.id}
                    $rowHeight={rowHeight}
                  >
                    {row.getVisibleCells().map((cell, i) => (
                      <S.Td
                        key={cell.id}
                        $rowHeight={rowHeight}
                        style={
                          i === 0
                            ? {
                                // The first column tries to be the size of the width of the table
                                // minus the combined with of all other columns.
                                width: `${maxWidthFirstCol}px`,
                                // However, it's max width is limited by the space available on the current
                                // screen width up to a point where it can be it's full width again.
                                maxWidth: `min(100vw - ${maxWidthFirstCol}px, ${maxWidthFirstCol}px)`,
                              }
                            : {
                                // All other columns are fixed sizes
                                width: cell.column.getSize(),
                              }
                        }
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </S.Td>
                    ))}
                  </S.Tr>
                ))}
                {table.getRowModel().rows.length === 0 && <tr></tr>}
              </tbody>
            </S.Table>
          </S.TableWrapper>
        )}
      </S.FiltersAndTableWrapper>
      {enablePagination && table.getPageCount() > 1 && (
        <DataTablePagination
          goToNextPage={() => {
            if (onPaginationClickCb) {
              onPaginationClickCb(table.getState().pagination.pageIndex + 2)
            }
            table.setPageIndex(table.getState().pagination.pageIndex + 1)
          }}
          goToPreviousPage={() => {
            if (onPaginationClickCb) {
              onPaginationClickCb(table.getState().pagination.pageIndex - 2)
            }
            table.setPageIndex(table.getState().pagination.pageIndex - 1)
          }}
          activeIndex={table.getState().pagination.pageIndex}
          pageItems={buildArrayFromInteger(table.getPageCount())}
          onPageItemClick={(pageIndex: number) => {
            if (onPaginationClickCb) {
              onPaginationClickCb(pageIndex + 1)
            }
            table.setPageIndex(pageIndex)
          }}
        />
      )}
    </S.TableOuterAreaWrapper>
  )
}

export const buildArrayFromInteger = (integer: number) => {
  const output: Array<number> = []
  for (let index = 0; index < integer; index++) {
    output.push(index)
  }
  return output
}
