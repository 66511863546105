import { Switch } from '@valuecase/ui-components'
import { SettingsInfo } from './SettingsInfo'
import { useCompanyVisualSettings } from './hooks/useCompanyVisualSettings'
import { useMemo } from 'react'
import { useNotifications } from '@/utils/Notifications/Notifications'

const AiWritingAssistantSettings = () => {
  const { success } = useNotifications()
  const { companySettings, updateCompanySettings } = useCompanyVisualSettings({
    onUpdateSuccess: () => {
      success('Settings updated successfully')
    },
  })

  const aiWritingAssistIsEnabled = useMemo(() => {
    return companySettings?.aiWritingAssistantIsEnabled
  }, [companySettings])

  const handleToggle = () => {
    updateCompanySettings({
      aiWritingAssistantIsEnabled: !aiWritingAssistIsEnabled,
    })
  }

  return (
    <div className='flex justify-between w-full items-start'>
      <SettingsInfo
        title='AI Writing Assistant'
        description='Enable the AI writing assistant. Data may be sent to OpenAI servers outside the EU for processing, but not used for training the AI.'
      />
      <div className='relative flex'>
        <Switch checked={aiWritingAssistIsEnabled} onChange={handleToggle} />
      </div>
    </div>
  )
}

export default AiWritingAssistantSettings
