import { useTranslation } from 'react-i18next'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { EmbedTypes, Loader } from '../../components/legacy'
import {
  Dialog,
  DialogBackButton,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Divider,
} from '../../components/ui'
import { EmbedSelect, TEmbedResult, TEmbedVariant } from './EmbedSelect'
import { Button2 } from '../../components/ui/button'
import { FileUploadArea, TAcceptedFileTypesGroup } from './FileUploadArea'
import { FileHelper } from '../../utils/assets'

import PreviewAfterUploadSrc from './assets/preview-after-upload.svg'
import EmbedThumbnail from './assets/embed-thumbnail.svg'
import NoPreviewAvailable from './assets/no-preview.svg'
import { generateThumbnail } from './thumbnail'
import { TIframelyResponse } from '@valuecase/common'
import {
  AssetPreview,
  AssetPreviewActions,
  AssetPreviewGrid,
  AssetPreviewMetadata,
  AssetPreviewThumbnailWrapper,
  AssetPreviewThumbnailLoadingPlaceholder,
  AssetPreviewThumbnailImage,
} from './AssetPreview'

type TDialogState =
  | { step: 'initial' }
  | ({
      step: 'filePreview'
      file: File
      customFileName?: string
      description?: string
    } & (
      | { previewType: 'PREVIEW_AFTER_UPLOAD' | 'NO_PREVIEW' }
      | {
          previewType: 'LIVE_PREVIEW'
          thumbnail: File | null
        }
    ))
  | ({ step: 'embedPreview'; name?: string; description?: string } & (
      | { embedType: 'html'; payload: string }
      | {
          embedType: Exclude<EmbedTypes, 'html'>
          url: string
          thumbnail: TLinkThumbnailResponse | null
        }
    ))
  | {
      step: 'linkPreview'
      url: string
      thumbnail: TLinkThumbnailResponse | null
      name?: string
      description?: string
    }
  | { step: 'uploading' }

export type TAssetDialogResult<RequireNameForEmbed extends boolean> =
  | (RequireNameForEmbed extends true
      ? {
          type: 'EMBED'
          embedType: 'html'
          payload: string
          name: string
          description?: string
        }
      : {
          type: 'EMBED'
          embedType: 'html'
          payload: string
          description?: string
        })
  | (RequireNameForEmbed extends true
      ? {
          type: 'EMBEDDABLE_LINK'
          embedType: Exclude<EmbedTypes, 'html'>
          url: string
          name: string
          description?: string
          thumbnail: TLinkThumbnailResponse
        }
      : {
          type: 'EMBEDDABLE_LINK'
          embedType: Exclude<EmbedTypes, 'html'>
          url: string
          name?: string
          description?: string
          thumbnail: TLinkThumbnailResponse
        })
  | {
      type: 'LINK'
      url: string
      thumbnail: TLinkThumbnailResponse
      name: string
      description?: string
    }
  | {
      type: 'FILE'
      file: File
      thumbnail: File | null
      customFileName?: string
      description?: string
    }

export interface TLinkThumbnailResponse {
  thumbnail_s3ObjectKey: string
  thumbnail_s3ObjectUrl: string
  iframelyResponse?: TIframelyResponse
}

export function AddAssetDialog<RequireNameForLinkAndEmbed extends boolean>(props: {
  title?: string
  subTitle?: string
  uploadButtonText: string
  open?: boolean
  onOpenChange?: (open: boolean) => void
  children?: ReactNode
  requireNameForEmbed?: RequireNameForLinkAndEmbed
  embedVariant: TEmbedVariant
  embedOrUploadCallback(result: TAssetDialogResult<RequireNameForLinkAndEmbed>): Promise<void>
  generateLinkThumbnail(url: string, useIframely?: boolean): Promise<TLinkThumbnailResponse>
  initialFile?: File
  acceptedFileTypes?: TAcceptedFileTypesGroup
  showDescription?: boolean
  showPreviewCallback?: (file?: File) => void
}) {
  const {
    title,
    subTitle,
    uploadButtonText,
    open = false,
    onOpenChange,
    children,
    embedOrUploadCallback,
    embedVariant = 'embedAndLink',
    requireNameForEmbed,
    initialFile,
    acceptedFileTypes,
    showDescription,
    showPreviewCallback,
  } = props

  const [dialogOpen, setDialogOpen] = useState(open)
  const { t } = useTranslation()

  const [dialogState, setDialogState] = useState<TDialogState>({ step: 'initial' })

  useEffect(() => {
    onOpenChange?.(dialogOpen)
    if (!dialogOpen) {
      setDialogState({ step: 'initial' })
    }
  }, [dialogOpen, onOpenChange])

  useEffect(() => {
    setDialogOpen(open)
  }, [open])

  const setFile = useCallback(
    async (file: File) => {
      const previewType = FileHelper.filePreviewType(file.type)

      setDialogState({
        step: 'filePreview',
        file,
        ...(previewType === 'LIVE_PREVIEW'
          ? { previewType: 'LIVE_PREVIEW', thumbnail: null }
          : { previewType }),
      })

      const thumbnail = await generateThumbnail(file)

      if (!thumbnail) {
        return
      }

      showPreviewCallback?.(file)

      setDialogState((prev) => {
        if (prev.step !== 'filePreview') {
          return prev
        }
        return {
          ...prev,
          thumbnail,
        }
      })
    },
    [showPreviewCallback],
  )

  useEffect(() => {
    if (initialFile) {
      setFile(initialFile)
    }
  }, [initialFile, setFile])

  const previewImage = useMemo(() => {
    if (dialogState.step !== 'filePreview') {
      return null
    }

    if (dialogState.previewType === 'NO_PREVIEW') {
      return <AssetPreviewThumbnailImage alt={'No Preview Available'} src={NoPreviewAvailable} />
    }

    if (dialogState.previewType === 'PREVIEW_AFTER_UPLOAD') {
      return <AssetPreviewThumbnailImage src={PreviewAfterUploadSrc} />
    }

    if (dialogState.previewType === 'LIVE_PREVIEW') {
      if (dialogState.thumbnail === null) {
        return <AssetPreviewThumbnailLoadingPlaceholder />
      } else {
        return <AssetPreviewThumbnailImage src={URL.createObjectURL(dialogState.thumbnail)} />
      }
    }
  }, [dialogState])

  const insertEmbed = useCallback(
    async (result: TEmbedResult) => {
      switch (result.type) {
        case 'EMBED': {
          const { embedType, payload } = result

          if (requireNameForEmbed) {
            setDialogState({
              step: 'embedPreview',
              embedType,
              payload,
            })
            showPreviewCallback?.()
            return
          }

          const embedResult: TAssetDialogResult<false> = {
            type: 'EMBED',
            embedType,
            payload,
          }
          await embedOrUploadCallback(embedResult as TAssetDialogResult<RequireNameForLinkAndEmbed>)
          setDialogOpen(false)
          return
        }
        case 'EMBEDDABLE_LINK': {
          const { embedType, url, previewName } = result

          setDialogState({
            step: 'embedPreview',
            embedType,
            url,
            thumbnail: null,
            name: undefined,
          })

          showPreviewCallback?.()

          const thumbnailAndOembedResponse = await props.generateLinkThumbnail(url, true)

          setDialogState((prev) => {
            if (prev.step !== 'embedPreview') {
              return prev
            }
            return {
              ...prev,
              thumbnail: thumbnailAndOembedResponse,
              name: thumbnailAndOembedResponse.iframelyResponse?.title ?? previewName,
              description:
                thumbnailAndOembedResponse.iframelyResponse?.description?.slice(0, 250) ??
                undefined,
            }
          })

          return
        }
        case 'LINK': {
          const { url, previewName } = result
          setDialogState({
            step: 'linkPreview',
            url,
            thumbnail: null,
            name: undefined,
          })

          showPreviewCallback?.()

          const thumbnailAndOembedResponse = await props.generateLinkThumbnail(url, true)

          setDialogState((prev) => {
            if (prev.step !== 'linkPreview') {
              return prev
            }
            return {
              ...prev,
              thumbnail: thumbnailAndOembedResponse,
              name: thumbnailAndOembedResponse.iframelyResponse?.title ?? previewName,
              description:
                thumbnailAndOembedResponse.iframelyResponse?.description?.slice(0, 250) ??
                undefined,
            }
          })

          return
        }
        default:
          throw new Error(`Unsupported embed result type ${result}`)
      }
    },
    [embedOrUploadCallback, props, requireNameForEmbed, showPreviewCallback],
  )

  const linkThumbnail = useCallback(
    (
      thumbnail: {
        thumbnail_s3ObjectKey: string
        thumbnail_s3ObjectUrl: string
      } | null,
    ) => {
      if (thumbnail === null) {
        return <AssetPreviewThumbnailLoadingPlaceholder />
      }

      return <AssetPreviewThumbnailImage src={thumbnail?.thumbnail_s3ObjectUrl} />
    },
    [],
  )

  const dialogView = useMemo(() => {
    switch (dialogState.step) {
      case 'initial':
        return (
          <div className='w-full flex flex-col gap-4'>
            <EmbedSelect variant={embedVariant} onInsert={insertEmbed} />
            <Divider text={t('components.AddFileModalBody.divider', { defaultValue: 'or' })} />
            <FileUploadArea
              className={'min-h-[186px]'}
              onFileSelected={setFile}
              acceptedFileTypes={acceptedFileTypes}
            />
          </div>
        )
      case 'filePreview':
        return (
          <AssetPreview>
            <AssetPreviewGrid>
              <AssetPreviewThumbnailWrapper
                description={`${FileHelper.getFileTypeDescriptionForFile(dialogState.file) ?? 'size'} • ${(dialogState.file.size / 1000000).toFixed(2)} MB`}
              >
                {previewImage}
              </AssetPreviewThumbnailWrapper>
              <AssetPreviewMetadata
                titlePlaceholder={fileNamePlaceholder(dialogState.file)}
                onTitleInput={(newName) => {
                  setDialogState((prev) => {
                    return {
                      ...prev,
                      customFileName: newName.currentTarget.value,
                    }
                  })
                }}
                titleValue={dialogState.customFileName}
                showDescription={showDescription}
                descriptionValue={dialogState.description}
                onDescriptionInput={(newName) => {
                  setDialogState((prev) => {
                    return {
                      ...prev,
                      description: newName.currentTarget.value,
                    }
                  })
                }}
              />
            </AssetPreviewGrid>
            <AssetPreviewActions onCancel={() => setDialogOpen(false)}>
              <Button2
                autoFocus
                className={'w-full'}
                onClick={async () => {
                  setDialogState({ step: 'uploading' })
                  await embedOrUploadCallback({
                    type: 'FILE',
                    file: dialogState.file,
                    customFileName: dialogState.customFileName,
                    thumbnail:
                      dialogState.previewType === 'LIVE_PREVIEW' ? dialogState.thumbnail : null,
                    description:
                      showDescription && (dialogState?.description?.length ?? 0) > 0
                        ? dialogState.description
                        : undefined,
                  })
                  setDialogOpen(false)
                }}
                leadingIcon={'cloud-upload-outline'}
              >
                {uploadButtonText}
              </Button2>
            </AssetPreviewActions>
          </AssetPreview>
        )
      case 'linkPreview':
        return (
          <AssetPreview>
            <AssetPreviewGrid>
              <AssetPreviewThumbnailWrapper>
                {linkThumbnail(dialogState.thumbnail)}
              </AssetPreviewThumbnailWrapper>
              <AssetPreviewMetadata
                titlePlaceholder={dialogState.name}
                disabled={dialogState.name === undefined}
                onTitleInput={(newName) => {
                  setDialogState((prev) => {
                    return {
                      ...prev,
                      name: newName.currentTarget.value,
                    }
                  })
                }}
                titleValue={dialogState.name}
                showDescription={showDescription}
                descriptionValue={dialogState.description}
                onDescriptionInput={(newName) => {
                  setDialogState((prev) => {
                    return {
                      ...prev,
                      description: newName.currentTarget.value,
                    }
                  })
                }}
              />
            </AssetPreviewGrid>
            <AssetPreviewActions onCancel={() => setDialogOpen(false)}>
              <Button2
                className={'w-full'}
                disabled={dialogState.thumbnail === null}
                onClick={async () => {
                  setDialogState({ step: 'uploading' })

                  if (dialogState.thumbnail === null) {
                    throw new Error('invalid state')
                  }

                  const linkResult: TAssetDialogResult<true> = {
                    type: 'LINK',
                    url: dialogState.url,
                    thumbnail: dialogState.thumbnail,
                    name: dialogState.name ?? dialogState.url,
                    description:
                      showDescription && (dialogState?.description?.length ?? 0) > 0
                        ? dialogState.description
                        : undefined,
                  }
                  await embedOrUploadCallback(
                    linkResult as TAssetDialogResult<RequireNameForLinkAndEmbed>,
                  )

                  setDialogOpen(false)
                }}
                leadingIcon={'cloud-upload-outline'}
              >
                {uploadButtonText}
              </Button2>
            </AssetPreviewActions>
          </AssetPreview>
        )
      case 'embedPreview':
        return (
          <AssetPreview>
            <AssetPreviewGrid>
              <AssetPreviewThumbnailWrapper>
                {dialogState.embedType === 'html' && (
                  <AssetPreviewThumbnailImage
                    alt={'Embed Thumbnail Placeholder'}
                    src={EmbedThumbnail}
                  />
                )}
                {dialogState.embedType !== 'html' && linkThumbnail(dialogState.thumbnail)}
              </AssetPreviewThumbnailWrapper>
              <AssetPreviewMetadata
                titlePlaceholder={
                  dialogState.embedType === 'html' ? dialogState.payload : dialogState.url
                }
                disabled={dialogState.embedType !== 'html' && dialogState.name === undefined}
                onTitleInput={(newName) => {
                  setDialogState((prev) => {
                    return {
                      ...prev,
                      name: newName.currentTarget.value,
                    }
                  })
                }}
                titleValue={dialogState.name}
                showDescription={showDescription}
                descriptionValue={dialogState.description}
                onDescriptionInput={(newName) => {
                  setDialogState((prev) => {
                    return {
                      ...prev,
                      description: newName.currentTarget.value,
                    }
                  })
                }}
              />
            </AssetPreviewGrid>
            <AssetPreviewActions onCancel={() => setDialogOpen(false)}>
              <Button2
                className={'w-full'}
                disabled={dialogState.embedType !== 'html' && dialogState.thumbnail === null}
                onClick={async () => {
                  setDialogState({ step: 'uploading' })

                  if (dialogState.embedType === 'html') {
                    const embedResult: TAssetDialogResult<true> = {
                      type: 'EMBED',
                      embedType: dialogState.embedType,
                      payload: dialogState.payload,
                      name: dialogState.name ?? dialogState.payload,
                      description:
                        showDescription && (dialogState?.description?.length ?? 0) > 0
                          ? dialogState.description
                          : undefined,
                    }
                    await embedOrUploadCallback(
                      embedResult as TAssetDialogResult<RequireNameForLinkAndEmbed>,
                    )
                  } else {
                    if (dialogState.thumbnail === null) {
                      throw new Error('invalid state')
                    }

                    const embedResult: TAssetDialogResult<true> = {
                      type: 'EMBEDDABLE_LINK',
                      embedType: dialogState.embedType,
                      url: dialogState.url,
                      name: dialogState.name ?? dialogState.url,
                      description:
                        showDescription && (dialogState?.description?.length ?? 0) > 0
                          ? dialogState.description
                          : undefined,
                      thumbnail: dialogState.thumbnail,
                    }
                    await embedOrUploadCallback(
                      embedResult as TAssetDialogResult<RequireNameForLinkAndEmbed>,
                    )
                  }
                  setDialogOpen(false)
                }}
                leadingIcon={'cloud-upload-outline'}
              >
                {uploadButtonText}
              </Button2>
            </AssetPreviewActions>
          </AssetPreview>
        )
      case 'uploading':
        return (
          <div className='w-full flex flex-col items-center content-center'>
            <Loader />
            <h4>
              {t('components.AddFileModalBody.uploadingMessage', { defaultValue: 'Uploading...' })}
            </h4>
          </div>
        )
      default:
        throw new Error(`unsupported dialog state ${dialogState}`)
    }
  }, [
    acceptedFileTypes,
    dialogState,
    embedOrUploadCallback,
    embedVariant,
    insertEmbed,
    linkThumbnail,
    previewImage,
    setFile,
    showDescription,
    t,
    uploadButtonText,
  ])

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      {!!children && <DialogTrigger asChild={true}>{children}</DialogTrigger>}
      <DialogContent className='w-full min-w-[554px] max-w-[554px] flex flex-col gap-10'>
        {(dialogState.step === 'filePreview' ||
          dialogState.step === 'linkPreview' ||
          dialogState.step === 'embedPreview') && (
          <DialogBackButton
            onClick={() => {
              setDialogState({ step: 'initial' })
            }}
          >
            {t('components.AddFileModalBody.backLabel', { defaultValue: 'Back' })}
          </DialogBackButton>
        )}

        <DialogHeader>
          <DialogTitle>
            {title ?? t('components.AddFileModalBody.title', { defaultValue: 'Add File' })}
          </DialogTitle>
          <DialogDescription>
            {subTitle ??
              t('components.AddFileModalBody.subtitle', {
                defaultValue: 'Upload website links, files from websites or local files.',
              })}
          </DialogDescription>
        </DialogHeader>
        {dialogView}
      </DialogContent>
    </Dialog>
  )
}

export function fileNamePlaceholder(original: File | null): string {
  if (!original) {
    return ''
  }

  const splittedName = original.name.split('.')
  if (splittedName.length === 1) {
    return splittedName[0]
  }
  splittedName.pop()
  return splittedName.join('.')
}
