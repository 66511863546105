import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  Input2,
  IonIcon,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@valuecase/ui-components'
import { useCallback, useEffect, useState } from 'react'
import { cn } from '../../utils/Cn'
import { useNotifications } from '../../utils/Notifications/Notifications'
import { useCurrentTenantQuery } from '../tenant/hooks/useReadTenant'

import { TUpsertCustomPropertyData, useUpsertCustomProperty } from './useUpsertCustomProperty'
import { SpacePropertyGptPromptEntry } from './SpacePropertyGptPromptEntry'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { useDeleteCustomProperty } from './useDeleteCustomProperty'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import { TCustomProperty } from '@valuecase/common'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import { Chip, ChipClose, ChipLabel } from '@valuecase/ui-components/src/components/ui/chip'

type SpacePropertyEntryProps = {
  data: Pick<TCustomProperty, 'key' | 'displayName' | 'options'> & {
    gptPrompt?: string
    id?: string
  }
  onCancel: () => void
  usedIn: null | string[]
}

function formatKeyName(keyName: string) {
  return keyName.replaceAll(' ', '_').toLowerCase()
}

export function SpacePropertyEntry(props: SpacePropertyEntryProps) {
  const { data, onCancel, usedIn } = props

  const [displayName, setDisplayName] = useState(data.displayName || '')
  const [key, setKey] = useState(data.key || '')
  const [options, setOptions] = useState(data.options || [])
  const [newOptionValue, setNewOptionValue] = useState('')
  const [status, setStatus] = useState<'display' | 'edit'>('display')
  const { tenant } = useCurrentTenantQuery()
  const { success } = useNotifications()
  const { trackEvent } = useTrackEvent()
  const { upsertCustomProperty } = useUpsertCustomProperty(() => {
    success(`Property ${!data.id ? 'created' : 'updated'} successfully`)
  })

  const { deleteCustomProperty } = useDeleteCustomProperty(() => {
    setDeleteModalVisible(false)
    success(`Property delete successfully`)

    trackEvent({
      event: 'custom-properties-delete-completed',
      eventProperties: {
        indexPageName: 'Space properties page',
      },
    })
  })

  const { flags } = useLDflags()
  const [showGptPromptInput, setShowGptPromptInput] = useState(false)
  const [showGptPromptEntry, setShowGptPromptEntry] = useState(!!data.gptPrompt || false)
  const [gptPrompt, setGptPrompt] = useState(data.gptPrompt || '')
  const isNewProperty = !data.id
  const gptEnabled = !!flags?.['gpt-for-space-properties']
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  function handleDisplayNameChange(value: string) {
    if (status === 'display') {
      return
    }

    setDisplayName(value)

    if (isNewProperty) {
      setKey(formatKeyName(value))
    }
  }

  function handleDisplayNameFocus() {
    if (status === 'display') {
      setStatus('edit')
    }
  }

  function handleKeyNameChange(value: string) {
    if (isNewProperty && displayName !== '') {
      setKey(formatKeyName(value))
    }
  }

  function handleOpenDeleteModal() {
    setDeleteModalVisible(true)

    trackEvent({
      event: 'custom-properties-delete-clicked',
      eventProperties: {
        indexPageName: 'Space properties page',
      },
    })
  }

  function deleteProperty() {
    if (!tenant?.payload.id || !data.id) {
      return
    }
    deleteCustomProperty({ tenantId: tenant.payload.id, customPropertyId: data.id })
  }

  function save() {
    if (!tenant?.payload.id) return

    const postData: TUpsertCustomPropertyData = {
      displayName,
      key,
      gptPrompt,
      options,
    }

    if (!isNewProperty) {
      postData.id = data.id
    }

    upsertCustomProperty({
      customProperty: postData,
      tenantId: tenant?.payload.id || '',
    })

    setShowGptPromptInput(false)
    setShowGptPromptEntry(!!gptPrompt)
    setStatus('display')
  }

  const cancel = useCallback(() => {
    if (isNewProperty) {
      onCancel()
    }
    if (!isNewProperty) {
      setDisplayName(data.displayName || '')
      setStatus('display')
      setOptions(data.options || [])
    }
    if (showGptPromptInput) {
      setShowGptPromptInput(false)
      setShowGptPromptEntry(!!gptPrompt)
    }
  }, [data.displayName, data.options, gptPrompt, isNewProperty, onCancel, showGptPromptInput])

  const handleEsc = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        if (status !== 'display') {
          cancel()
        }
      }
    },
    [cancel, status],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [handleEsc])

  return (
    <>
      <div
        className={cn('w-[50em] flex flex-col gap-3', {
          'rounded-lg bg-white border border-grey-s3 p-3': status === 'edit' || isNewProperty,
        })}
      >
        <div className='grid grid-cols-[1fr_1fr] gap-4'>
          <div className={'flex gap-2'}>
            <Input2
              value={displayName}
              onChange={(e) => handleDisplayNameChange(e.target.value)}
              placeholder='Insert display name'
              onFocus={handleDisplayNameFocus}
            />
            {gptEnabled && (
              <Button2
                variant={'outlined'}
                leadingIcon={'sparkles-sharp'}
                onClick={() => {
                  if (!showGptPromptInput) {
                    setShowGptPromptInput(true)
                    setShowGptPromptEntry(false)
                    setStatus('edit')
                  } else {
                    setShowGptPromptInput(false)
                    setShowGptPromptEntry(false)
                  }
                }}
              />
            )}
          </div>

          <div>
            <Input2
              value={key}
              disabled={!isNewProperty || displayName === ''}
              onChange={(e) => handleKeyNameChange(e.target.value)}
              placeholder='Insert property key name'
            />
          </div>
        </div>
        {showGptPromptInput && (
          <SpacePropertyGptPromptEntry
            value={gptPrompt}
            onDiscard={() => {
              setGptPrompt('')
              setShowGptPromptEntry(false)
              setShowGptPromptInput(false)
            }}
            onAccept={(newPrompt) => {
              const trimmedPrompt = newPrompt.trim()
              setGptPrompt(trimmedPrompt)
              setShowGptPromptEntry(!!trimmedPrompt)
              setShowGptPromptInput(false)
            }}
          />
        )}
        {showGptPromptEntry && (
          <div className='flex flex-col text-xs'>
            <div className={'flex gap-2'}>
              <IonIcon className={'w-4 h-4 flex-shrink-0'} name={'sparkles-sharp'} />
              <div className={'flex flex-col'}>
                <h4 className={'font-bold'}>Prompt</h4>
                <p>{gptPrompt}</p>
              </div>
            </div>
          </div>
        )}

        {status === 'display' && data.options.length > 0 && (
          <div className='flex flex-col text-xs'>
            <div className={'flex gap-2'}>
              <IonIcon className={'w-4 h-4 flex-shrink-0'} name={'c_dropdown'} />
              <div className={'flex flex-col'}>
                <h4 className={'font-bold'}>Placeholder values</h4>
                <p>{data.options.join(', ')}</p>
              </div>
            </div>
          </div>
        )}

        {status === 'edit' && (
          <>
            <div className='flex flex-col text-xs'>
              <div className={'flex gap-2'}>
                <IonIcon className={'w-4 h-4 flex-shrink-0'} name={'c_dropdown'} />
                <div className={'flex flex-col gap-4'}>
                  <div className={'flex flex-col'}>
                    <h4 className={'font-bold'}>Placeholder values</h4>
                    <p className={'text-grey-s5'}>
                      Facilitate your team a set of values to choose from when adding values.
                    </p>
                  </div>

                  <div className={'flex gap-2'}>
                    <Input2
                      value={newOptionValue}
                      placeholder='Insert value'
                      onChange={(e) => {
                        setNewOptionValue(e.target.value)
                      }}
                    />
                    <Button2
                      disabled={newOptionValue === '' || options.includes(newOptionValue)}
                      variant={'subtle'}
                      leadingIcon={'add'}
                      onClick={() => {
                        setOptions([...options, newOptionValue.trim()])
                        setNewOptionValue('')
                      }}
                    >
                      Add value
                    </Button2>
                  </div>

                  <div className={'flex flex-wrap gap-2'}>
                    {options.map((option) => {
                      return (
                        <Chip key={option}>
                          <ChipLabel>{option}</ChipLabel>
                          <ChipClose
                            onClick={() => {
                              setOptions(options.filter((o) => o !== option))
                            }}
                          />
                        </Chip>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className={'flex w-full justify-between'}>
              <Tooltip>
                <TooltipTrigger>
                  {!isNewProperty && (
                    <Button2
                      onClick={handleOpenDeleteModal}
                      disabled={(usedIn?.length ?? 0) > 0}
                      variant={'plain'}
                      leadingIcon={'trash'}
                    >
                      Delete property
                    </Button2>
                  )}
                </TooltipTrigger>
                <TooltipContent className='max-w-[288px]' hidden={(usedIn?.length ?? 0) === 0}>
                  Used in template: {usedIn?.join(', ')}
                </TooltipContent>
              </Tooltip>

              <div className={'flex gap-3'}>
                <Button2 variant={'outlined'} onClick={cancel}>
                  Cancel
                </Button2>
                <Button2 disabled={key === '' || displayName === ''} onClick={save}>
                  Save property
                </Button2>
              </div>
            </div>
          </>
        )}
      </div>

      <AlertDialog open={deleteModalVisible} onOpenChange={setDeleteModalVisible}>
        <AlertDialogContent>
          <AlertDialogHeader iconName='trash'>Delete space property?</AlertDialogHeader>
          <AlertDialogDescription>
            Are you sure you want to delete this space property? This action cannot be undone.
          </AlertDialogDescription>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteProperty}>Delete</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
