import { Activity, formatDateShort, WeaklyTypedActivity } from '@valuecase/common'
import { GetTenantApiResponse } from '@valuecase/seller-backend'
import { DateTime } from 'luxon'
import { JSX } from 'react'
import { placeholderValuePairs } from './PlaceholderValuePair'
import { RichTextFormatHelper } from './RichTextFormatHelper'

export function getIcon(type: Activity['type']): string {
  switch (type) {
    case 'VIEW':
      return 'browsers-outline'
    case 'CLICK':
      return 'checkmark-outline'
    case 'ATTACHMENT_LINK_COPIED':
      return 'attach-outline'
    case 'ATTACHMENT_PREVIEW':
      return 'attach-outline'
    case 'ATTACHMENT_DOWNLOAD':
      return 'attach-outline'
    case 'LINK_CLICKED':
      return 'document-text-outline'
    case 'DIRECT_LINK_TO_FILE_CLICKED':
      return 'attach-outline'
    case 'MAP_MILESTONE_TASK_DETAILS_OPENED':
      return 'checkmark-outline'
    case 'MAP_MILESTONE_TASK_COMPLETED':
      return 'checkmark-outline'
    case 'MAP_MILESTONE_TASK_ADDED':
      return 'checkmark-outline'
    case 'MAP_MILESTONE_TASK_DELETED':
      return 'trash-outline'
    case 'MAP_MILESTONE_TASK_ASSIGNEE_ADDED':
      return 'checkmark-outline'
    case 'MAP_MILESTONE_TASK_ASSIGNEE_DELETED':
      return 'trash-outline'
    case 'MAP_MILESTONE_TASK_ASSIGNEE_CHANGED':
      return 'checkmark-outline'
    case 'MAP_MILESTONE_TASK_DUE_DATE_ADDED':
      return 'checkmark-outline'
    case 'MAP_MILESTONE_TASK_DUE_DATE_DELETED':
      return 'trash-outline'
    case 'MAP_MILESTONE_TASK_DELETE_FILE':
      return 'trash-outline'
    case 'MAP_MILESTONE_TASK_DESCRIPTION_DELETED':
      return 'trash-outline'
    case 'MAP_MILESTONE_TASK_DUE_DATE_CHANGED':
      return 'checkmark-outline'
    case 'MAP_MILESTONE_TASK_ATTACHMENT_UPLOADED':
      return 'checkmark-outline'
    case 'MAP_MILESTONE_TASK_DOWNLOAD_FILE':
      return 'download-outline'
    case 'MAP_MILESTONE_TASK_QUICK_ACTION_CLICK':
      return 'checkmark-outline'
    case 'MAP_MILESTONE_ADDED':
      return 'checkmark-outline'
    case 'MAP_MILESTONE_COMPLETED':
      return 'checkmark-outline'
    case 'MAP_MILESTONE_TASK_CHANGED':
      return 'create-outline'
    case 'BUSINESS_CASE_RECALCULATED':
      return 'calculator-outline'
    case 'MAP_MILESTONE_TASK_DESCRIPTION_CHANGED':
    case 'MAP_MILESTONE_TASK_DESCRIPTION_ADDED':
      return 'create-outline'
    case 'SPACE_LINK_COPIED':
      return 'copy-outline'
    case 'COMMENT':
      return 'chatbubble-outline'
    case 'SPACE_COMMENT':
      return 'chatbubble-outline'
    case 'MAP_MILESTONE_DELETED':
      return 'trash-outline'
    case 'BLOCK_LINK_COPIED':
      return 'link-outline'
    case 'INLINE_VIDEO_PLAY':
      return 'play-outline'
    case 'BLOCK_INTRO_VIDEO_PLAY':
      return 'play-outline'
    case 'PDF_CONTENT_VIEW':
      return 'document-outline'
    case 'TESTIMONIAL_VIEW':
      return 'person-outline'
    case 'ATTACHMENT_PREVIEW_FULLSCREEN':
      return 'phone-landscape-outline'
    case 'COLLABORATIVE_BLOCK_EDITED':
      return 'create-outline'
    case 'INTERACTIVE_LIST_ITEM_OPENED':
      return 'list-outline'
    case 'ARCHIVED_VIEW':
      return 'file-tray-outline'
    case 'BUYER_NOTIFICATION_SENT_MILESTONE_DONE':
    case 'BUYER_NOTIFICATION_SENT_TASK_ASSIGNED':
    case 'BUYER_NOTIFICATION_SENT_COMMENT':
    case 'BUYER_NOTIFICATION_SENT_SPACE_COMMENT':
    case 'BUYER_NOTIFICATION_SENT_TASK_DUE_TODAY':
      return 'mail-outline'
    default:
      return ''
  }
}

export function getActivityTitle(data: WeaklyTypedActivity, tenant?: GetTenantApiResponse) {
  const { defaultPlaceholdableProps } = placeholderValuePairs(data)
  const getBlockTitle = (data: any): string | undefined => {
    try {
      return RichTextFormatHelper.getPlainTextFromContent(
        data.block?.versions[0].asset?.data?.title,
        defaultPlaceholdableProps,
      )
    } catch (e) {
      return undefined
    }
  }

  const { metadata } = data

  const buyerName = data.name || data.email || 'A stakeholder'
  const taskName = metadata?.taskName || metadata?.taskTitle || 'Unknown task'
  // legacy events of type MAP_MILESTONE_COMPLETED have taskName instead of milestoneName
  const milestoneName = metadata?.milestoneName || metadata?.taskName || 'Unknown milestone'
  const assigneeName = metadata?.assigneeName || 'Unknown asignee'
  const dueDate = metadata?.dueDate || 'Unknown due date'
  const action = metadata?.quickActionName || 'Unknown action'
  const listItemTitle = metadata?.listItemTitle || 'Unknown list item'
  const link = metadata?.link || 'Unknown link'

  const fileName =
    metadata?.fileName || data.node?.data?.fileName || data.node?.data?.linkName || 'Unknown file'

  const pageName = () => {
    const name =
      data.page?.versions?.[0].asset?.data?.title || data.node?.data?.title || 'Unknown page'
    return <span className='bold'>{name}</span>
  }

  const blockName = () => {
    return <span className='bold'>{getBlockTitle(data) || 'Unknown block'}</span>
  }

  const templates: Partial<Record<Activity['type'], () => JSX.Element>> = {
    VIEW: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> visited the page {pageName()}
      </div>
    ),
    ATTACHMENT_LINK_COPIED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> copied the link to the file{' '}
        <span className='bold'>{fileName}</span>
      </div>
    ),
    DIRECT_LINK_TO_FILE_CLICKED: () => (
      <div className='activity-body'>
        A direct link to the file <span className='bold'>{fileName}</span> has been clicked.
      </div>
    ),
    ATTACHMENT_PREVIEW: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> opened the preview for file{' '}
        <span className='bold'>{fileName}</span>
      </div>
    ),
    ATTACHMENT_DOWNLOAD: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> downloaded the file{' '}
        <span className='bold'>{fileName}</span>
      </div>
    ),
    ATTACHMENT_PREVIEW_FULLSCREEN: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> opened the file{' '}
        <span className='bold'>{fileName}</span> in the block {blockName()} in full screen mode
      </div>
    ),

    // MAP
    MAP_MILESTONE_TASK_DETAILS_OPENED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> opened the task details of the task{' '}
        <span className='bold'>{taskName}</span>
      </div>
    ),

    MAP_MILESTONE_TASK_COMPLETED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> completed the task{' '}
        <span className='bold'>{taskName}</span>
      </div>
    ),
    MAP_MILESTONE_TASK_ADDED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> added the task{' '}
        <span className='bold'>{taskName}</span>
      </div>
    ),
    MAP_MILESTONE_TASK_CHANGED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> changed a task name to{' '}
        <span className='bold'>{taskName}</span>
      </div>
    ),
    MAP_MILESTONE_TASK_DELETED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> deleted the task{' '}
        <span className='bold'>{taskName}</span>
      </div>
    ),

    // MAP TASK ASSIGNEE
    MAP_MILESTONE_TASK_ASSIGNEE_ADDED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> assigned{' '}
        <span className='bold'>{assigneeName}</span> to the task{' '}
        <span className='bold'>{taskName}</span>.
      </div>
    ),
    MAP_MILESTONE_TASK_ASSIGNEE_DELETED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> deleted the assignee{' '}
        <span className='bold'>{assigneeName}</span> from task{' '}
        <span className='bold'>{taskName}</span>.
      </div>
    ),

    // MAP TASK DUE DATE
    MAP_MILESTONE_TASK_DUE_DATE_ADDED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> set the due date{' '}
        <span className='bold'>{formatDateShort(DateTime.fromISO(dueDate), tenant?.locale)}</span>{' '}
        for task <span className='bold'>{taskName}</span>.
      </div>
    ),
    MAP_MILESTONE_TASK_DUE_DATE_DELETED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> deleted the due date of task{' '}
        <span className='bold'>{taskName}</span>
      </div>
    ),
    MAP_MILESTONE_TASK_DUE_DATE_CHANGED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> changed the due date of the task{' '}
        <span className='bold'>{taskName}</span> to{' '}
        {formatDateShort(DateTime.fromISO(dueDate), tenant?.locale)}
      </div>
    ),

    // MAP TASK FILES
    MAP_MILESTONE_TASK_ATTACHMENT_UPLOADED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> uploaded a file to the task{' '}
        <span className='bold'>{taskName}</span>
      </div>
    ),
    MAP_MILESTONE_TASK_DOWNLOAD_FILE: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> downloaded the file{' '}
        <span className='bold'>{fileName}</span> from the task{' '}
        <span className='bold'>{taskName}</span>
      </div>
    ),
    MAP_MILESTONE_TASK_DELETE_FILE: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> deleted the file{' '}
        <span className='bold'>{fileName}</span> from the task{' '}
        <span className='bold'>{taskName}</span>
      </div>
    ),

    // MAP TASK OTHER
    MAP_MILESTONE_TASK_QUICK_ACTION_CLICK: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> clicked on the quick action{' '}
        <span className='bold'>{action}</span> of the task <span className='bold'>{taskName}</span>
      </div>
    ),

    // MAP DESCRIPTION
    MAP_MILESTONE_TASK_DESCRIPTION_ADDED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> added a description to the task{' '}
        <span className='bold'>{taskName}</span>
      </div>
    ),
    MAP_MILESTONE_TASK_DESCRIPTION_CHANGED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> edited the description of the task{' '}
        <span className='bold'>{taskName}</span>
      </div>
    ),
    MAP_MILESTONE_TASK_DESCRIPTION_DELETED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> deleted the description of the task{' '}
        <span className='bold'>{taskName}</span>
      </div>
    ),

    // MAP MILESTONE
    MAP_MILESTONE_ADDED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> added the milestone{' '}
        <span className='bold'>{milestoneName}</span> on page {pageName()}.
      </div>
    ),
    MAP_MILESTONE_DELETED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> deleted the milestone{' '}
        <span className='bold'>{milestoneName}</span> on page {pageName()}.
      </div>
    ),
    MAP_MILESTONE_COMPLETED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> completed the milestone{' '}
        <span className='bold'>{milestoneName}</span>
      </div>
    ),

    // BUSINESS CASE
    BUSINESS_CASE_RECALCULATED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> recalculated the interactive calculator
        results on page {pageName()}.
      </div>
    ),

    SPACE_LINK_COPIED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> copied the invite link
      </div>
    ),

    PDF_CONTENT_VIEW: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> read pages 1 to {metadata.maxPageRead} of
        the document <span className='bold'>{metadata.fileName}</span> in the block {blockName()}.
      </div>
    ),
    TESTIMONIAL_VIEW: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> interacted {metadata.interactions} times
        with the testimonial block {blockName()}
      </div>
    ),
    INLINE_VIDEO_PLAY: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> watched the video{' '}
        <span className='bold'>{metadata.fileName}</span> in the block {blockName()}
      </div>
    ),
    BLOCK_INTRO_VIDEO_PLAY: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> watched the block intro video of the block{' '}
        {blockName()}
      </div>
    ),
    COLLABORATIVE_BLOCK_EDITED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> edited the collaborative text block{' '}
        {blockName()}
      </div>
    ),
    INTERACTIVE_LIST_ITEM_OPENED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> opened the interactive list item{' '}
        <span className='bold'>{listItemTitle}</span>
      </div>
    ),

    COMMENT: () => {
      if (data.node?.type === 'BLOCK_MAP_MILESTONE_TASK') {
        return (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> added a comment to the task{' '}
            <span className='bold'>{taskName}</span>
          </div>
        )
      } else {
        return (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> wrote a comment on block{' '}
            <span className='bold'>{blockName()}</span>
          </div>
        )
      }
    },

    SPACE_COMMENT: () => {
      return (
        <div className='activity-body'>
          <span className='buyerTitle'>{buyerName}</span> added a general comment to the space{' '}
          <span className='bold'>{data.node?.data?.companyName || 'Untitled'}</span>
        </div>
      )
    },

    BLOCK_LINK_COPIED: () => {
      return (
        <div className='activity-body'>
          <span className='buyerTitle'>{buyerName}</span> copied the link to block{' '}
          <span className='bold'>{blockName()}</span>
        </div>
      )
    },
    ARCHIVED_VIEW: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> tried to access this archived space. Maybe a
        good opportunity to reconnect?
      </div>
    ),
    BUYER_NOTIFICATION_SENT_MILESTONE_DONE: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>
          A notification email was sent to{' '}
          <span className='bold'>{metadata?.notifiedUserEmail || 'a stakeholder'}</span>
        </span>{' '}
        for a completed milestone on block <span className='bold'>{blockName()}</span>
      </div>
    ),
    BUYER_NOTIFICATION_SENT_TASK_ASSIGNED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>
          A notification email was sent to <span className='bold'>{buyerName}</span>
        </span>{' '}
        because a task was assigned to them on block <span className='bold'>{blockName()}</span>
      </div>
    ),
    BUYER_NOTIFICATION_SENT_COMMENT: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>
          A notification email was sent to <span className='bold'>{buyerName}</span>
        </span>{' '}
        for a comment on block <span className='bold'>{blockName()}</span>
      </div>
    ),
    BUYER_NOTIFICATION_SENT_SPACE_COMMENT: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>
          A notification email was sent to <span className='bold'>{buyerName}</span>
        </span>{' '}
        for a general comment on the space
      </div>
    ),
    BUYER_NOTIFICATION_SENT_TASK_DUE_TODAY: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>
          A notification email was sent to <span className='bold'>{buyerName}</span>
        </span>{' '}
        because one of their tasks is due.
      </div>
    ),

    LINK_CLICKED: () => (
      <div className='activity-body'>
        <span className='buyerTitle'>{buyerName}</span> opened the link{' '}
        <span className={'bold'}>{link}</span> on page {pageName()}.
      </div>
    ),
  }

  try {
    const template = templates[data.type]
    if (!template) {
      return null
    }
    return template()
  } catch (e) {
    return null
  }
}

export function getSpacesIdsList(spaces: any, ownerId?: string) {
  if (!spaces || spaces?.length === 0) return []

  const spacesIdsList: string[] = []

  for (const space of spaces) {
    if (ownerId) {
      if (space.ownerId === ownerId) {
        spacesIdsList.push(space.id)
      }
    } else {
      spacesIdsList.push(space.id)
    }
  }

  const uniqueSpaces = [...new Set([...spacesIdsList])]
  return uniqueSpaces
}
