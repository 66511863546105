import { useEffect, useState } from 'react'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { S } from './Spaces.styled'
import SpacesList from './components/spacesList/spacesList'
import { SpaceFilterProvider } from './hooks/useSpaceFiltering'

const Spaces = () => {
  const [mixpanel_pageVisit_Tracked, set_mixpanel_pageVisit_Tracked] = useState(false)
  const { trackEvent } = useTrackEvent()

  useEffect(() => {
    if (!mixpanel_pageVisit_Tracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'Space List',
        },
      })
      set_mixpanel_pageVisit_Tracked(true)
    }
  }, [mixpanel_pageVisit_Tracked, trackEvent])

  return (
    <S.Spaces data-intercom-target='Spaces List'>
      <SpacesList />
    </S.Spaces>
  )
}

const SpacesWithFilterProvider = () => (
  <SpaceFilterProvider>
    <Spaces />
  </SpaceFilterProvider>
)

export default SpacesWithFilterProvider
