import { useCallback, useEffect, useRef, useState } from 'react'
import { useOverlay, ModalControls } from '@valuecase/ui-components'
import TemplateCreatorModal from './TemplateCreator/TemplateCreatorModal'
import TemplatesList from './TemplatesList/TemplatesList'
import { useReadTemplates } from './hooks/useReadTemplates'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { TemplateCreatorLibraryItemData } from './types/TemplateCreatorLibraryItemData'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'

const Templates = () => {
  const overlay = useOverlay()
  const { trackEvent } = useTrackEvent()
  const [mixpanel_pageVisit_Tracked, set_mixpanel_pageVisit_Tracked] = useState(false)

  const controls = useRef<ModalControls | null>(null)
  const { templates } = useReadTemplates()

  const showCreateTemplateOverlay = useCallback(
    (templateToSelect?: TemplateCreatorLibraryItemData) => {
      overlay.show(
        <TemplateCreatorModal controlsRef={controls} initialSelectedTemplate={templateToSelect} />,
      )
    },
    [overlay],
  )

  useEffect(() => {
    if (!mixpanel_pageVisit_Tracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'Template List',
        },
      })
      set_mixpanel_pageVisit_Tracked(true)
    }
  }, [mixpanel_pageVisit_Tracked, trackEvent])
  return (
    <div className='flex flex-col py-11 w-full'>
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-col w-118'}>
          <h1 className={'text-2xl font-bold'}>Templates</h1>
          <p className={'text-sm text-grey-s5 mt-2 leading-6'}>
            Explore and manage company templates, create new ones, and discover fresh inspiration
            from our constantly expanding template library.
          </p>
        </div>
        <div className={'flex items-center'}>
          <Button2
            trailingIcon={'add'}
            onClick={() => {
              trackEvent({
                event: 'templates-newtemplate-open',
                eventProperties: {
                  indexPageName: 'Templates List',
                },
              })

              showCreateTemplateOverlay()
            }}
          >
            Create New Template
          </Button2>
        </div>
      </div>
      <div className='w-full border-grey-s2 border-t-[1px] mt-10' />
      {templates && (
        <TemplatesList showCreateTemplate={showCreateTemplateOverlay} templates={templates} />
      )}
    </div>
  )
}

export default Templates
