import { useSpaceFilter } from '@/modules/spaces/hooks/useSpaceFiltering'
import useReadMyTeams from '@/modules/teams/hooks/useReadMyTeams'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuContentScrollable,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@valuecase/ui-components'
import React, { useCallback, useMemo } from 'react'

const SpaceByTeamsFilter: React.FC = () => {
  const { tenant } = useCurrentTenantQuery()
  const { myTeams } = useReadMyTeams()
  const {
    collaboratorAccess,
    companyTeamAccess,
    myTeamAccess,
    teamIds,
    setTeamIds,
    setCollaboratorAccess,
    setMyTeamAccess,
    setCompanyTeamAccess,
  } = useSpaceFilter()

  const teamFilterLabel = useMemo(() => {
    if (myTeamAccess) {
      return 'My Teams'
    }

    if (collaboratorAccess) {
      return 'Collaborator access'
    }

    if (companyTeamAccess) {
      return tenant?.payload?.tenantName ?? 'Everyone in the company'
    }

    if (!teamIds) {
      return 'Any team'
    }

    if (teamIds?.length === 1) {
      const team = myTeams?.find((team) => team.id === teamIds[0])
      return team?.name ?? 'Team (1)'
    }

    if (teamIds?.length > 1) {
      return `Teams (${teamIds?.length})`
    }

    return ''
  }, [
    collaboratorAccess,
    companyTeamAccess,
    myTeamAccess,
    myTeams,
    teamIds,
    tenant?.payload?.tenantName,
  ])

  const updateTeamSelection = useCallback(
    (teamId: string) => {
      // No teams were selected, now selecting the first one and deselecting the other filters
      if (!teamIds || teamIds?.length === 0) {
        setTeamIds?.([teamId])
        setCollaboratorAccess?.(false)
        setCompanyTeamAccess?.(false)
        return
      }
      // At this point at least one team is already selected
      if (teamIds.includes(teamId)) {
        // If the team is already selected, deselect it. If it was the only team selected, set
        // the team filter to null
        const newTeamIds = teamIds?.filter((id) => id !== teamId)
        if (!newTeamIds?.length) {
          setTeamIds?.(null)
          return
        } else {
          setTeamIds?.(teamIds?.filter((id) => id !== teamId))
        }
        return
      }
      // At this point, at least one team is selected but the target team is not selected
      else {
        // If the target team is not selected, add it to the selected teams
        setTeamIds?.([...teamIds, teamId])
      }
    },
    [setCollaboratorAccess, setCompanyTeamAccess, setTeamIds, teamIds],
  )

  const handleCollaboratorAccessChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setCompanyTeamAccess?.(false)
        setTeamIds?.(null)
        setMyTeamAccess?.(false)
        setCollaboratorAccess?.(true)
      } else {
        setCollaboratorAccess?.(false)
      }
    },
    [setCollaboratorAccess, setCompanyTeamAccess, setMyTeamAccess, setTeamIds],
  )

  const handleCompanyTeamAccessChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setCollaboratorAccess?.(false)
        setTeamIds?.(null)
        setMyTeamAccess?.(false)
        setCompanyTeamAccess?.(true)
      } else {
        setCompanyTeamAccess?.(false)
      }
    },
    [setCollaboratorAccess, setCompanyTeamAccess, setMyTeamAccess, setTeamIds],
  )

  const handleMyTeamAccessChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setCollaboratorAccess?.(false)
        setTeamIds?.(null)
        setCompanyTeamAccess?.(false)
        setMyTeamAccess?.(true)
      } else {
        setMyTeamAccess?.(false)
      }
    },
    [setCollaboratorAccess, setCompanyTeamAccess, setMyTeamAccess, setTeamIds],
  )

  const handleAnyTeamAccessChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setCollaboratorAccess?.(false)
        setTeamIds?.(null)
        setCompanyTeamAccess?.(false)
        setMyTeamAccess?.(false)
      }
    },
    [setCollaboratorAccess, setCompanyTeamAccess, setMyTeamAccess, setTeamIds],
  )

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownMenuButton icon='person'>{teamFilterLabel}</DropdownMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='start'>
        <DropdownMenuCheckboxItem
          checked={!teamIds?.length && !myTeamAccess && !collaboratorAccess && !companyTeamAccess}
          onCheckedChange={handleAnyTeamAccessChange}
        >
          Any team
        </DropdownMenuCheckboxItem>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          checked={myTeamAccess || false}
          onCheckedChange={handleMyTeamAccessChange}
        >
          My Teams
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={collaboratorAccess || false}
          onCheckedChange={handleCollaboratorAccessChange}
        >
          Other teams (Collaborator access)
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={companyTeamAccess || false}
          onCheckedChange={handleCompanyTeamAccessChange}
        >
          Everyone in {tenant?.payload?.tenantName ?? 'company'}
        </DropdownMenuCheckboxItem>
        {myTeams && myTeams?.length > 0 && <DropdownMenuSeparator />}
        <DropdownMenuContentScrollable>
          {myTeams?.map((team) => {
            return (
              <DropdownMenuCheckboxItem
                key={team?.id}
                checked={teamIds?.includes(team.id)}
                onCheckedChange={() => updateTeamSelection(team.id)}
                descriptionVariant={'stacked'}
              >
                {team.name}
              </DropdownMenuCheckboxItem>
            )
          })}
        </DropdownMenuContentScrollable>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default SpaceByTeamsFilter
