import { useState } from 'react'
import { Button } from '@valuecase/ui-components'
import SellerApi from '../../api/SellerApi'

const UploadTenantsFromBubbleComponent = () => {
  const [importFile, setImportFile] = useState<File | null>()
  const [output, setOutput] = useState('')

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        const doUploadTenantsData = async () => {
          try {
            if (!importFile) {
              throw new Error('No file selected')
            }
            const formData = new FormData()
            formData.append('file', importFile)
            const res = await SellerApi.post('/tenants-import/bubble', formData, {
              config: {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              },
            })
            setOutput(JSON.stringify(res))
          } catch (err) {
            setOutput('Error (see console)')
            console.error(err)
          }
        }
        doUploadTenantsData()
      }}
    >
      <h2>Update tenants from bubble</h2>
      <input
        type='file'
        onChange={(event) => setImportFile(event.target.files && event.target.files[0])}
        accept={'.json'}
        multiple={false}
      />
      <p>⚠️ Warning! If a tenant with the same id already exists, it will be skipped</p>
      <Button label='Upload tenants from Bubble' submitButton={true} />
      <div>
        <pre>{output}</pre>
      </div>
    </form>
  )
}

export default UploadTenantsFromBubbleComponent
