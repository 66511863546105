import * as PopoverPrimitive from '@radix-ui/react-popover'
import { ComponentPropsWithoutRef, ElementRef, forwardRef, ReactNode } from 'react'
import { cn } from '../../lib/utils'
import { IonIcon } from '../../Icons/IonIcon'

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverContent = forwardRef<
  ElementRef<typeof PopoverPrimitive.Content>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    headline?: ReactNode
    hideClose?: boolean
  }
>(({ className, align = 'center', sideOffset = 4, hideClose, children, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        'z-50 w-72 rounded-lg border border-grey-s2 bg-white p-4 shadow-md outline-none',
        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0',
        'data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2',
        'data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      {...props}
    >
      <div className={'flex items-center justify-between flex-row-reverse'}>
        {!hideClose && (
          <PopoverPrimitive.Close
            className={'flex items-center justify-center h-6 w-6 group'}
            onClick={(e) => e.stopPropagation()}
          >
            <IonIcon
              name={'close'}
              className={'w-3 h-3 text-grey-s5 group-hover:text-grey-s6'}
            ></IonIcon>
          </PopoverPrimitive.Close>
        )}
        {props.headline && (
          <div className={'text-grey-s6 mx-2 text-xs font-semibold flex gap-2'}>
            {props.headline}
          </div>
        )}
      </div>
      {children}
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>
))
PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverTrigger, PopoverContent }
