import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  formatDateShort,
  formatDateTimeShort,
  TAssetLibraryEntry,
  TAssetLibraryQuery,
} from '@valuecase/common'
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table'
import { IonIcon } from '../../Icons/IonIcon'
import { DateTime } from 'luxon'
import { Button2, ButtonLink } from '../../components/ui/button'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTrigger,
  DataTable,
  DataTablePagination,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input2,
  OneLineTruncatedText,
  Skeleton,
  Textarea,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  useNotifications,
} from '../../components/ui'
import { cn } from '../../lib'
import { useDebounceValue } from 'usehooks-ts'
import {
  EmptyState,
  EmptyStateAction,
  EmptyStateDescription,
  EmptyStateHeader,
  EmptyStateTitle,
} from '../../components/ui/empty'
import { Loader } from '../../components/legacy'
import { PanelTabsList, PanelTabsTrigger, Tabs } from '../../components/ui/tabs'

import {
  useAssetLibraryFolder,
  useAssetLibraryFolders,
  useAssetLibraryItems,
  useMutateAssetLibraryEntry,
} from './hooks'
import { assetDescription } from '../../utils'
import { AssetFolderButton } from './AssetFolder'

const gridStyling = 'grid md:grid-cols-3 xl:grid-cols-4 auto-rows-[max-content] gap-3'

export const AssetTable = ({
  onAddAsset,
  showActions,
  locale,
  onAssetClick,
  className,
  tableWrapperClassName,
  assetTypes,
  mimeTypes,
  gridOverflowScroll,
  disableClickOnEmbeds,
  parentFolderId: paramsParentFolderId,
  onParentFolderIdChange,
}: {
  onAddAsset: () => void
  showActions?: boolean
  locale?: string
  onAssetClick?: (asset: TAssetLibraryEntry) => void
  className?: string
  tableWrapperClassName?: string
  assetTypes?: TAssetLibraryQuery['types']
  mimeTypes?: TAssetLibraryQuery['mimeTypes']
  gridOverflowScroll?: boolean
  disableClickOnEmbeds?: boolean
  parentFolderId?: string | null
  onParentFolderIdChange?: (id: string | null) => void
}) => {
  const [view, setView] = useState<'LIST' | 'GRID'>('GRID')

  const pageSize = 12
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  })

  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounceValue(search, 300)

  const [parentFolderId, setParentFolderId] = useState<string | null>(null)

  useEffect(() => {
    setParentFolderId(paramsParentFolderId ?? null)
  }, [paramsParentFolderId])

  const { data: folders } = useAssetLibraryFolders({
    parentFolderId,
  })

  const { data: currentFolder } = useAssetLibraryFolder(parentFolderId ?? undefined)

  const { items, totalPages, itemsExist, isLoading } = useAssetLibraryItems({
    page: pagination.pageIndex + 1,
    itemsPerPage: pagination.pageSize,
    search: debouncedSearch || undefined,
    types: assetTypes,
    mimeTypes,
    folderId: debouncedSearch.length > 0 ? undefined : parentFolderId, // search works globally
  })

  const itemsOrFoldersExists = useMemo(
    () => itemsExist || (folders?.length ?? 0) > 0,
    [itemsExist, folders],
  )

  const assetThumbnail = useCallback((asset: TAssetLibraryEntry['asset']) => {
    if (asset.type === 'EMBED') {
      return <IonIcon name={'code-slash-outline'} className={'w-6 h-6 text-grey-s3'} />
    }

    if (asset.type === 'EMBEDDABLE_LINK') {
      return (
        <img
          className={'object-cover w-full h-full'}
          src={asset.data.thumbnail_s3ObjectUrl}
          alt={asset.data.name}
        />
      )
    }

    if (asset.type === 'LINK') {
      return (
        <img
          className={'object-cover w-full h-full'}
          src={asset.data.thumbnail_s3ObjectUrl}
          alt={asset.data.linkName}
        />
      )
    }

    if (asset.type === 'FILE' && asset.data.thumbnail_s3ObjectUrl) {
      return (
        <img
          className={'object-cover w-full h-full'}
          src={asset.data.thumbnail_s3ObjectUrl}
          alt={asset.data.fileName}
        />
      )
    }

    return <IonIcon name={'document'} className={'w-6 h-6 text-grey-s3'} />
  }, [])

  const columns = useMemo<ColumnDef<TAssetLibraryEntry>[]>(
    () => [
      {
        id: 'name',
        header: 'Name',
        size: 600,
        cell: (ctx) => {
          const { asset, name } = ctx.row.original

          return (
            <div className={'flex items-center gap-3'}>
              <div
                className={cn(
                  'w-10 h-10 flex-shrink-0 rounded-lg border border-solid border-grey-s2 overflow-hidden',
                  'bg-primary-s1 flex justify-center items-center',
                )}
              >
                {assetThumbnail(asset)}
              </div>
              <div className={'flex flex-col gap-1'}>
                <p
                  className={
                    'text-sm font-semibold max-h-[3em] line-clamp-2 overflow-hidden overflow-ellipsis'
                  }
                >
                  {ctx.row.original.name}
                </p>
                {ctx.row.original.description && (
                  <OneLineTruncatedText className={'text-xs text-grey-s5'}>
                    {ctx.row.original.description}
                  </OneLineTruncatedText>
                )}
              </div>
            </div>
          )
        },
      },
      {
        id: 'type',
        header: 'Type',
        size: 80,
        cell: (ctx) => assetDescription(ctx.row.original.asset),
      },
      {
        id: 'createdBy',
        header: 'Created by',
        cell: ({
          row: {
            original: { createdBy },
          },
        }) => {
          return (
            <span className={'text-xs'}>
              {createdBy.firstName} {createdBy.lastName}
            </span>
          )
        },
      },
      {
        id: 'lastModified',
        header: 'Last modified',
        cell: (ctx) => {
          if (!ctx.row.original.updatedAt) {
            return null
          }
          return formatDateTimeShort(DateTime.fromJSDate(ctx.row.original.updatedAt), locale)
        },
      },
      {
        id: 'actions',
        size: 100,
        cell: (ctx) => {
          const { asset } = ctx.row.original

          return (
            <div
              className={'flex gap-2'}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {asset.type === 'FILE' && (
                <ButtonLink
                  variant={'plain'}
                  leadingIcon={'open-outline'}
                  size={'small'}
                  href={asset.data.s3ObjectUrl}
                  target={'_blank'}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              )}
              {(asset.type === 'LINK' ||
                asset.type === 'EMBEDDABLE_LINK' ||
                (asset.type === 'EMBED' && asset.data.embedType !== 'html')) && (
                <ButtonLink
                  variant={'plain'}
                  leadingIcon={'open-outline'}
                  size={'small'}
                  href={
                    asset.type === 'LINK' || asset.type === 'EMBEDDABLE_LINK'
                      ? asset.data.url
                      : asset.data.payload
                  }
                  target={'_blank'}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              )}
              {asset.type === 'EMBED' && asset.data.embedType === 'html' && (
                <Tooltip>
                  <TooltipTrigger>
                    <Button2
                      variant={'plain'}
                      leadingIcon={'open-outline'}
                      size={'small'}
                      disabled={true}
                    />
                  </TooltipTrigger>
                  <TooltipContent>HTML embeds can not be previewed</TooltipContent>
                </Tooltip>
              )}
              {showActions && <AssetActionsDropdown entry={ctx.row.original} />}
            </div>
          )
        },
      },
    ],
    [assetThumbnail, locale, showActions],
  )

  const assetTable = useReactTable<(typeof items)[number]>({
    columns,
    data: items,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    state: {
      pagination,
    },
    onPaginationChange: (state) => {
      if (typeof state === 'function') {
        const newState = state(pagination)
        setPagination(newState)
      }
    },
    manualSorting: true,
    manualPagination: true,
    pageCount: totalPages,
  })

  if (!itemsOrFoldersExists && isLoading) {
    return (
      <div className={'w-full h-full flex justify-center items-center'}>
        <Loader />
      </div>
    )
  }

  return (
    <>
      {!itemsOrFoldersExists && parentFolderId === null ? (
        <div className={cn('h-full flex items-center justify-center', className)}>
          <EmptyState icon={'file-tray-outline'} iconSize={'large'}>
            <EmptyStateHeader>
              <EmptyStateTitle> No assets uploaded to your library yet.</EmptyStateTitle>
              <EmptyStateDescription>
                Add files and embeds to the library to make them available to everyone in your
                company.
              </EmptyStateDescription>
            </EmptyStateHeader>
            <EmptyStateAction onClick={onAddAsset}>Add asset</EmptyStateAction>
          </EmptyState>
        </div>
      ) : (
        <div
          className={cn('flex flex-col gap-4', className, {
            'h-full overflow-hidden': gridOverflowScroll,
          })}
        >
          <div className={'flex justify-between'}>
            <div className={'w-[320px]'}>
              <Input2
                placeholder={'Search assets in all folders...'}
                leadingIcon={'search'}
                value={search}
                onInput={(e) => setSearch(e.currentTarget.value)}
              />
            </div>
            <Tabs value={view} onValueChange={(value) => setView(value as 'GRID' | 'LIST')}>
              <PanelTabsList>
                <PanelTabsTrigger className={'p-2'} value={'GRID'}>
                  <IonIcon name={'grid-outline'} className={'w-4 h-4 text-grey-s5'} />
                </PanelTabsTrigger>
                <PanelTabsTrigger className={'p-2'} value={'LIST'}>
                  <IonIcon name={'list'} className={'w-4 h-4 text-grey-s5'} />
                </PanelTabsTrigger>
              </PanelTabsList>
            </Tabs>
          </div>

          <div
            className={cn('flex flex-col gap-4 flex-grow', {
              'overflow-scroll': gridOverflowScroll,
            })}
          >
            {!debouncedSearch && (
              <>
                {parentFolderId !== null && (
                  <div className={'flex gap-2 items-center'}>
                    <Button2
                      className={'flex-shrink-0'}
                      size={'small'}
                      variant={'outlined'}
                      leadingIcon={'folder-open'}
                      onClick={() => {
                        if (onParentFolderIdChange) {
                          onParentFolderIdChange(null)
                        } else {
                          setParentFolderId(null)
                        }
                      }}
                    >
                      Library
                    </Button2>
                    {currentFolder && (
                      <OneLineTruncatedText>
                        <p className={'text-lg font-bold'}>
                          <span className={'font-light'}>/ </span> {currentFolder.name}
                        </p>
                      </OneLineTruncatedText>
                    )}
                  </div>
                )}

                {!!folders && folders.length > 0 && (
                  <>
                    <h3 className={'font-bold text-sm'}>Folders</h3>
                    <div className={cn(gridStyling)}>
                      {folders.map((f) => (
                        <AssetFolderButton
                          folder={f}
                          showActions={showActions}
                          key={f.id}
                          onClick={() => {
                            if (onParentFolderIdChange) {
                              // we use external state management
                              onParentFolderIdChange(f.id)
                            } else {
                              setParentFolderId(f.id)
                            }
                          }}
                        />
                      ))}
                    </div>
                  </>
                )}
              </>
            )}

            {items.length > 0 && <h3 className={'font-bold text-sm'}>Files</h3>}

            {items.length === 0 && !debouncedSearch && (
              <div className={cn('h-full flex items-center justify-center', className)}>
                <EmptyState icon={'file-tray-outline'}>
                  <EmptyStateHeader>
                    <EmptyStateDescription>No assets in this folder yet.</EmptyStateDescription>
                  </EmptyStateHeader>
                  <EmptyStateAction leadingIcon={'cloud-upload'} onClick={onAddAsset}>
                    Add assets
                  </EmptyStateAction>
                </EmptyState>
              </div>
            )}

            {view === 'LIST' && (items.length > 0 || debouncedSearch) && (
              <DataTable
                className={'flex  flex-col flex-shrink flex-grow overflow-scroll'}
                rowClickHandler={onAssetClick ? (row) => onAssetClick(row.original) : undefined}
                isLoading={isLoading}
                table={assetTable}
                tableWrapperClassName={cn(
                  'rounded-lg border border-grey-s2',
                  tableWrapperClassName,
                )}
                resetFiltersClickHandler={() => {
                  setSearch('')
                }}
              />
            )}
            {view === 'GRID' && (
              <>
                {items.length === 0 && debouncedSearch && (
                  <div className={'w-full h-full flex justify-center items-center'}>
                    <EmptyState icon={'search'} iconSize={'small'}>
                      <EmptyStateHeader>
                        <EmptyStateTitle>No matches found!</EmptyStateTitle>
                        <EmptyStateDescription>
                          Try changing or clearing out your filters.
                        </EmptyStateDescription>
                      </EmptyStateHeader>
                      <EmptyStateAction onClick={() => setSearch('')}>
                        Reset filter
                      </EmptyStateAction>
                    </EmptyState>
                  </div>
                )}

                {items.length > 0 && (
                  <div className={'overflow-scroll flex-shrink flex-grow-0'}>
                    <div className={cn(gridStyling)}>
                      {isLoading &&
                        Array.from({ length: pageSize }).map((i) => (
                          <Skeleton key={i as number} className={'h-[206px] rounded-lg'}></Skeleton>
                        ))}

                      {items.map((entry) => (
                        <AssetLibraryGridCard
                          key={entry.id}
                          entry={entry}
                          locale={locale}
                          showActions={showActions}
                          onClick={() => {
                            onAssetClick?.(entry)
                          }}
                          disableClickOnEmbeds={disableClickOnEmbeds}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {totalPages > 1 && (
                  <DataTablePagination
                    className={'mb-1'}
                    goToNextPage={() => {
                      setPagination({
                        ...pagination,
                        pageIndex: pagination.pageIndex + 1,
                      })
                    }}
                    goToPreviousPage={() => {
                      setPagination({
                        ...pagination,
                        pageIndex: pagination.pageIndex - 1,
                      })
                    }}
                    activeIndex={pagination.pageIndex}
                    pageItems={Array.from({ length: totalPages }, (_, index) => index)}
                    onPageItemClick={(pageIndex: number) => {
                      setPagination({
                        ...pagination,
                        pageIndex,
                      })
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const AssetLibraryGridCard = ({
  entry,
  locale,
  showActions,
  onClick,
  disableClickOnEmbeds,
}: {
  entry: TAssetLibraryEntry
  locale?: string
  showActions?: boolean
  onClick?: () => void
  disableClickOnEmbeds?: boolean
}) => {
  const { asset, id, name, updatedAt } = entry
  const [dropdownOpen, setDropdownOpen] = useState(false)

  // This state is used to hide the action only with a debounce and reduce flickering
  const [debouncedCardHovered, setDebouncedCardHovered] = useDebounceValue(false, 150)
  useEffect(() => {
    if (!dropdownOpen) {
      setDebouncedCardHovered(false)
    }
  }, [dropdownOpen, setDebouncedCardHovered])

  return (
    <div
      className={cn(
        'group flex flex-col bg-white rounded-lg border border-solid border-grey-s2 shadow-sm overflow-hidden',
        'relative',
        {
          'transition-colors cursor-pointer': !(
            asset.type === 'EMBED' &&
            asset.data.embedType === 'html' &&
            disableClickOnEmbeds
          ),
        },
      )}
      onMouseEnter={() => setDebouncedCardHovered(true)}
      onMouseLeave={() => setDebouncedCardHovered(false)}
      onClick={onClick}
    >
      <div
        className={
          'absolute right-3 top-3 text-white bg-[#767880]/80 backdrop-blur text-xxs font-semibold rounded px-1 leading-4'
        }
      >
        {assetDescription(entry.asset)}
      </div>
      {(asset.type === 'FILE' || asset.type === 'LINK' || asset.type === 'EMBEDDABLE_LINK') && (
        <div
          className={cn(
            'w-full h-36 overflow-hidden flex-shrink-0 flex justify-center items-center',
          )}
        >
          {asset.data.thumbnail_s3ObjectUrl ? (
            <img
              className={'object-cover w-full h-full'}
              src={asset.data.thumbnail_s3ObjectUrl}
              alt={name}
            />
          ) : (
            <IonIcon name={'document'} className={'text-grey-s3 w-12 h-12'}></IonIcon>
          )}
        </div>
      )}
      {asset.type === 'EMBED' && (
        <div className={'w-full h-36 pt-3 flex items-center justify-center flex-shrink-0'}>
          <IonIcon name={'code-slash-outline'} className={'text-grey-s3 w-12 h-12'}></IonIcon>
        </div>
      )}
      <div className={'h-[52px]'}></div>
      <div
        className={cn(
          'absolute p-3 flex flex-col w-full gap-2',
          'rounded-t-lg bg-white/95 backdrop-blur-lg',
          'shadow-[0px_-1px_4px_rgba(0,0,0,0.1)]',
          '-bottom-2',
          {
            'group-hover:bg-white/90': !(
              asset.type === 'EMBED' &&
              asset.data.embedType === 'html' &&
              disableClickOnEmbeds
            ),
          },
        )}
      >
        <div className={'flex justify-between gap-3 w-full'}>
          <div className={'flex flex-col gap-1 h-full'}>
            <OneLineTruncatedText className={'text-xs font-bold'}>{name}</OneLineTruncatedText>

            <p className={'text-xs text-grey-s4'}>
              <span className={'align-middle'}>
                <IonIcon
                  name={'cloud-upload-outline'}
                  className={'text-grey-s3 w-3 h3 flex-shrink-0 mt-[2px] mr-1'}
                ></IonIcon>
              </span>
              on {formatDateShort(DateTime.fromJSDate(updatedAt), locale)} by{' '}
              {entry.createdBy.firstName} {entry.createdBy.lastName}
            </p>
          </div>
          {showActions && (
            <div
              className={cn('hidden group-hover:block', {
                block: dropdownOpen || debouncedCardHovered,
              })}
            >
              <AssetActionsDropdown entry={entry} onDropdownOpenChange={setDropdownOpen} />
            </div>
          )}
        </div>
        <div
          className={cn(
            'max-h-0 group-hover:max-h-[112px] transition-[max-height] duration-300',
            'text-xs text-grey-s5 overflow-scroll',
            { 'max-h-[112px]': dropdownOpen },
          )}
        >
          <p
            className={cn('pb-2', {
              italic: !entry.description,
            })}
          >
            {entry.description ?? 'No description'}
          </p>
        </div>
      </div>
    </div>
  )
}

const AssetActionsDropdown = ({
  entry,
  onDropdownOpenChange,
}: {
  entry: TAssetLibraryEntry
  onDropdownOpenChange?: (open: boolean) => void
}) => {
  const { updateAsset, deleteAsset } = useMutateAssetLibraryEntry()
  const { success, error } = useNotifications()

  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)
  const [hasOpenDialog, setHasOpenDialog] = useState(false)

  const [descriptionValue, setDescriptionValue] = useState<string>()

  useEffect(() => {
    setDescriptionValue(entry.description ?? undefined)
  }, [entry.description, hasOpenDialog])

  const handleDialogItemOpenChange = useCallback(
    (open: boolean) => {
      setHasOpenDialog(open)
      if (!open) {
        setDropdownMenuOpen(false)
        onDropdownOpenChange?.(false)
      }
    },
    [onDropdownOpenChange],
  )

  return (
    <DropdownMenu
      open={dropdownMenuOpen}
      onOpenChange={(open) => {
        setDropdownMenuOpen(open)
        onDropdownOpenChange?.(open)
      }}
      modal={false}
    >
      <DropdownMenuTrigger asChild>
        <Button2
          onClick={(e) => e.stopPropagation()}
          variant={'plain'}
          leadingIcon={'ellipsis-vertical'}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align={'end'}
        className={cn({
          hidden: hasOpenDialog || !dropdownMenuOpen,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <Dialog onOpenChange={handleDialogItemOpenChange}>
          <DialogTrigger>
            <DropdownMenuItem
              onSelect={(event) => {
                event.preventDefault()
              }}
            >
              <div className={'flex gap-2 items-center'}>
                <IonIcon name={'create-outline'} className={'w-4 h-4 text-grey-s4'} />
                <p>Edit description</p>
              </div>
            </DropdownMenuItem>
          </DialogTrigger>
          <DialogContent onClick={(e) => e.stopPropagation()}>
            <DialogHeader>
              <DialogTitle>Edit description</DialogTitle>
            </DialogHeader>

            <div className={'flex flex-col gap-2'}>
              <p className={'text-grey-s4 text-xs font-semibold'}>Description</p>
              <Textarea
                maxLength={250}
                className={'h-20 resize-none'}
                value={descriptionValue}
                onInput={(e) => {
                  setDescriptionValue(e.currentTarget.value)
                }}
              />
            </div>

            <DialogFooter className='grid grid-cols-2 gap-3'>
              <DialogClose asChild>
                <Button2 variant={'outlined'} leadingIcon='close-circle-outline'>
                  Cancel
                </Button2>
              </DialogClose>
              <DialogClose asChild>
                <Button2
                  onClick={async () => {
                    try {
                      await updateAsset({
                        id: entry.id,
                        asset: entry.asset,
                        name: entry.name,
                        description: (descriptionValue?.length ?? 0) > 0 ? descriptionValue : null,
                      })
                      success('Asset successfully updated')
                    } catch (e) {
                      error('Failed to update asset')
                    }
                  }}
                >
                  Save changes
                </Button2>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <AlertDialog onOpenChange={handleDialogItemOpenChange}>
          <AlertDialogTrigger>
            <DropdownMenuItem
              onSelect={(event) => {
                event.preventDefault()
              }}
            >
              <div className={'flex gap-2 items-center'}>
                <IonIcon name={'trash-outline'} className={'w-4 h-4 text-warning-s4'} />
                <p className={'text-warning-s5'}>Delete</p>
              </div>
            </DropdownMenuItem>
          </AlertDialogTrigger>
          <AlertDialogContent onClick={(e) => e.stopPropagation()}>
            <AlertDialogHeader iconName='trash'>Delete asset?</AlertDialogHeader>
            <AlertDialogDescription>
              Are you sure you want to delete this asset? Please note that if this asset is used in
              any space, it will not be removed from there.
            </AlertDialogDescription>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                onClick={async () => {
                  try {
                    await deleteAsset(entry.id)
                    success('Asset successfully deleted')
                  } catch (e) {
                    error('Failed to delete asset')
                  }
                }}
              >
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
