import { FC, useCallback, useEffect, useState } from 'react'
import TemplateCreatorView from './TemplateCreatorView'
import { TemplateCreatorSteps } from '../types/TemplateCreatorSteps'
import { TemplateCreatorProps } from '../types/TemplateCreatorProps'
import { useModalControls } from '@valuecase/ui-components'
import { useCreateTemplate } from '../hooks/useCreateTemplate'
import { TemplateCreatorForm } from './TemplateCreatorParts.styled'
import { useReadPublicTemplates } from '../hooks/useReadPublicTemplates'
import { TemplateIconSource } from '../types/TemplateIconSource'
import { TemplateCreatorCreateMode } from '../types/TemplateCreatorCreateMode'
import { VLoaderAnimationFullSizeCentered } from '../../../ui-components/VLoader/VLoader'
import { useTrackEvent } from '../../../mixpanel/useTrackEvent'
import { TemplateCreatorLibraryItemData } from '../types/TemplateCreatorLibraryItemData'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'
import useReadMyTeams from '@/modules/teams/hooks/useReadMyTeams'
import useReadTeams from '@/modules/teams/hooks/useReadTeams'

const emptyTemplate = {
  id: '',
  title: 'Empty',
  description: 'Use this empty template to start building a template from scratch',
  createdAt: '2023-02-20 13:20:28.332',
  updatedAt: '2023-02-20 13:20:28.332',
  createdBy: 'None',
} as TemplateCreatorLibraryItemData

const TemplateCreator: FC<TemplateCreatorProps> = ({
  setIsModalPersistent,
  initialSelectedTemplate,
}) => {
  const [mixpanelEventEditDescriptionTracked, set_mixpanelEventEditDescriptionTracked] =
    useState(false)
  const { trackEvent } = useTrackEvent()
  const { close } = useModalControls()
  const createMode: TemplateCreatorCreateMode = initialSelectedTemplate ? 'duplicate' : 'new'
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateCreatorLibraryItemData | null>(
    initialSelectedTemplate || emptyTemplate,
  )
  const { tenant } = useCurrentTenantQuery()
  const { myTeams } = useReadMyTeams()
  const [selectedTeamIds, setSelectedTeamIds] = useState<Set<string> | undefined>(
    Array.isArray(myTeams)
      ? myTeams.length === 0
        ? new Set()
        : myTeams.length === 1
          ? new Set([myTeams[0].id])
          : undefined
      : undefined,
  )
  const [newTemplateName, setNewTemplateName] = useState<string>(
    initialSelectedTemplate?.title || '',
  )
  const [newTemplateUsage, setNewTemplateUsage] = useState<string>(
    initialSelectedTemplate?.description || '',
  )
  const { data: teams } = useReadTeams()

  //trigger callback when the user finishes typing in the DescriptionTextArea
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        //user finishes typing a new description
        newTemplateUsage !== '' &&
        initialSelectedTemplate?.description !== newTemplateUsage
      ) {
        if (!mixpanelEventEditDescriptionTracked) {
          trackEvent({
            event: 'templates-newtemplate-edit_description',
            eventProperties: {
              indexPageName: 'templates',
              isNewTemplate: true,
              templateID: 'TemplateNotYetCreated',
            },
          })
          set_mixpanelEventEditDescriptionTracked(true)
        }
      }
    }, 500)

    return () => clearTimeout(timeout)
  }, [
    newTemplateUsage,
    setNewTemplateUsage,
    initialSelectedTemplate?.description,
    trackEvent,
    mixpanelEventEditDescriptionTracked,
  ])

  useEffect(() => {
    // If the current users's teams just loaded and a team selection has not yet been made, then
    // make a selection based on the current user's teams
    if (Array.isArray(myTeams) && !selectedTeamIds) {
      if (myTeams.length === 0) {
        setSelectedTeamIds(new Set()) // Empty Set = select whole tenant
      } else if (myTeams.length === 1) {
        setSelectedTeamIds(new Set([myTeams[0].id]))
      }
      // If the user is a member of multiple teams, no selection is made for the user
    }
  }, [myTeams, selectedTeamIds])

  const [newTemplateIcon, setNewTemplateIcon] = useState<TemplateIconSource>(
    initialSelectedTemplate?.icon
      ? {
          source: initialSelectedTemplate.icon.source,
          emojiNative: initialSelectedTemplate.emojiIcon,
        }
      : {
          source: 'emoji-native',
          emojiNative: '🗒️',
        },
  )
  const [currentStep, goToStep] = useState<TemplateCreatorSteps>(
    initialSelectedTemplate ? 'enterInfo' : 'selectTemplate',
  )
  const { isLoading, templates } = useReadPublicTemplates()
  const templateList = [emptyTemplate].concat(templates || [])

  const { createTemplate, error } = useCreateTemplate({
    onSuccess: (createdTemplate) => {
      setIsModalPersistent(false)
      close()
      trackEvent({
        event: 'templates-newtemplate-created',
        eventProperties: {
          indexPageName: 'templates',
          isNewTemplate: true,
          templateID: createdTemplate.id,
        },
      })
      if (!createdTemplate) return
      location.pathname = `/spaces/${createdTemplate.id}`
    },
    onError: () => {
      setIsModalPersistent(false)
      goToStep('enterInfo')
    },
  })

  const selectTemplate = useCallback(
    (templateId: string) => {
      if (!Array.isArray(templateList)) {
        throw new Error('Could not select template on empty template list')
      }
      const newlySelectedTemplate = templateList.find((template) => template.id === templateId)
      if (!newlySelectedTemplate) {
        throw new Error('Tried to select a non-existent template')
      }

      setSelectedTemplate(newlySelectedTemplate)
      trackEvent({
        event: 'templates-newtemplate-select',
        eventProperties: {
          indexPageName: 'templates',
          templateID: newlySelectedTemplate.id,
          templateName: newlySelectedTemplate.title,
        },
      })
      setNewTemplateName(newlySelectedTemplate.title)
      setNewTemplateUsage(newlySelectedTemplate.description || '')
      if (newlySelectedTemplate.icon) {
        setNewTemplateIcon({
          source: 'emoji-native',
          // TODO fix typing
          emojiNative: (newlySelectedTemplate.icon as unknown as string) || '🗒️',
        })
      }
    },
    [templateList, trackEvent],
  )

  const createNewTemplate = useCallback(() => {
    setIsModalPersistent(true)
    goToStep('loading')
    const data = {
      description: newTemplateUsage,
      sourceTemplateId: selectedTemplate?.rootNodeId,
      title: newTemplateName,
      icon: newTemplateIcon,
      teamIds: selectedTeamIds && Array.from(selectedTeamIds),
    }
    createTemplate(data)
  }, [
    setIsModalPersistent,
    newTemplateUsage,
    selectedTemplate?.rootNodeId,
    newTemplateName,
    newTemplateIcon,
    selectedTeamIds,
    createTemplate,
  ])

  return (
    <TemplateCreatorForm
      onSubmit={(event) => {
        // Form element used for browser semantics, but submission
        // actually handled via createNewTemplate callback
        event.preventDefault()
      }}
    >
      {isLoading && <VLoaderAnimationFullSizeCentered />}
      {!isLoading && Array.isArray(templateList) && (
        <TemplateCreatorView
          error={error}
          templateList={templateList}
          selectTemplate={selectTemplate}
          selectedTemplate={selectedTemplate}
          newTemplateName={newTemplateName}
          newTemplateUsage={newTemplateUsage}
          newTemplateIcon={newTemplateIcon}
          setNewTemplateName={setNewTemplateName}
          setNewTemplateUsage={setNewTemplateUsage}
          setNewTemplateIcon={setNewTemplateIcon}
          createNewTemplate={createNewTemplate}
          currentStep={currentStep}
          goToStep={goToStep}
          createMode={createMode}
          teams={teams}
          selectedTeamIds={selectedTeamIds}
          setSelectedTeamIds={setSelectedTeamIds}
          tenantName={tenant?.payload?.tenantName || ''}
        />
      )}
    </TemplateCreatorForm>
  )
}

export default TemplateCreator
