import { FC, useCallback, useMemo } from 'react'
import { Button, DottedPagination, Hairline } from '@valuecase/ui-components'
import { TemplateCreatorFacts } from './TemplateCreatorFacts'
import { TemplateCreatorLibrary } from './TemplateCreatorLibrary'
import {
  TemplateCreatorCurrentStepWrapper,
  TemplateCreatorExistingTemplateDetails,
  TemplateCreatorFooter,
  TemplateCreatorHeaderSubtitle,
  TemplateCreatorHeaderTitle,
  TemplateCreatorHeaderWrapper,
  TemplateCreatorLayout,
  TemplateCreatorLibraryWrapper,
  TemplateCreatorNextButtonWrapper,
  TemplateCreatorPaginationWrapper,
  TemplateCreatorBackButtonWrapper,
  TemplateCreatorStep1Layout,
  TemplateCreatorErrorWrapper,
  TemplateCreatorFooterRight,
} from './TemplateCreatorParts.styled'
import { TemplateCreatorViewProps } from '../types/TemplateCreatorViewProps'
import { VLoaderAnimation } from '../../../ui-components/VLoader/VLoader'
import { useTrackEvent } from '../../../mixpanel/useTrackEvent'
import TemplateInputForm from '../TemplateInputForm'

const TemplateCreatorView: FC<TemplateCreatorViewProps> = ({
  error,
  templateList,
  selectTemplate,
  selectedTemplate,
  newTemplateName,
  newTemplateUsage,
  newTemplateIcon,
  setNewTemplateName,
  setNewTemplateUsage,
  setNewTemplateIcon,
  createNewTemplate,
  currentStep,
  goToStep,
  createMode,
  teams,
  selectedTeamIds,
  setSelectedTeamIds,
  tenantName,
}) => {
  const { trackEvent } = useTrackEvent()
  const goToNextStep = useCallback(() => {
    switch (currentStep) {
      case 'selectTemplate':
        goToStep('enterInfo')
        trackEvent({
          event: 'templates-newtemplate-next_click',
          eventProperties: {
            indexPageName: 'templates',
          },
        })
        break
      case 'enterInfo':
        createNewTemplate()
        goToStep('loading')
        break
      default:
        break
    }
  }, [createNewTemplate, currentStep, goToStep, trackEvent])

  const subtitle = (() => {
    switch (currentStep) {
      case 'selectTemplate':
        return 'Start from scratch or choose a template from the library.'
      case 'enterInfo':
        return createMode === 'new'
          ? 'Add information that will help your colleagues to understand how to use this template.'
          : 'Adjust template information before creating a duplicate.'
      default:
        return ''
    }
  })() as string

  const hasTeams = useMemo(() => !!teams?.length, [teams])

  return (
    <>
      <TemplateCreatorHeaderWrapper>
        <TemplateCreatorHeaderTitle>
          {createMode === 'new' ? 'Create a Template' : 'Duplicate Template'}
        </TemplateCreatorHeaderTitle>
        <TemplateCreatorHeaderSubtitle>{subtitle}</TemplateCreatorHeaderSubtitle>
      </TemplateCreatorHeaderWrapper>
      <Hairline />
      <TemplateCreatorLayout>
        <TemplateCreatorCurrentStepWrapper>
          {currentStep === 'selectTemplate' && (
            <TemplateCreatorStep1Layout>
              <TemplateCreatorLibraryWrapper>
                <TemplateCreatorLibrary
                  templateList={templateList}
                  selectTemplate={selectTemplate}
                  selectedTemplateId={selectedTemplate?.id}
                />
              </TemplateCreatorLibraryWrapper>
              <TemplateCreatorExistingTemplateDetails>
                <TemplateCreatorFacts
                  template={selectedTemplate}
                  isAnEmptyTemplate={selectedTemplate?.title === 'Empty'}
                />
              </TemplateCreatorExistingTemplateDetails>
            </TemplateCreatorStep1Layout>
          )}
          {currentStep === 'enterInfo' && (
            <TemplateInputForm
              newTemplateName={newTemplateName}
              setNewTemplateName={setNewTemplateName}
              newTemplateUsage={newTemplateUsage}
              setNewTemplateUsage={setNewTemplateUsage}
              newTemplateIcon={newTemplateIcon}
              setNewTemplateIcon={setNewTemplateIcon}
              teams={teams}
              selectedTeamIds={selectedTeamIds}
              setSelectedTeamIds={setSelectedTeamIds}
              tenantName={tenantName}
            />
          )}
          {currentStep === 'loading' && (
            <div
              style={{
                minHeight: '30vh',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <VLoaderAnimation />
            </div>
          )}
          {currentStep === 'success' && <div>Your template has been created</div>}
        </TemplateCreatorCurrentStepWrapper>
      </TemplateCreatorLayout>
      <Hairline />
      {currentStep !== 'loading' && currentStep !== 'success' && (
        <TemplateCreatorFooter>
          <div>
            {currentStep === 'enterInfo' && createMode === 'new' && (
              <TemplateCreatorBackButtonWrapper>
                <Button
                  style='flat'
                  icon='arrow-back'
                  iconPosition='left'
                  label='Back'
                  onClick={() => goToStep('selectTemplate')}
                />
              </TemplateCreatorBackButtonWrapper>
            )}
          </div>
          <TemplateCreatorPaginationWrapper>
            {createMode === 'new' && (
              <DottedPagination page={currentStep === 'enterInfo' ? 1 : 0} pageCount={2} />
            )}
          </TemplateCreatorPaginationWrapper>
          <TemplateCreatorFooterRight>
            {!!error && typeof error === 'object' && typeof error.message === 'string' && (
              <TemplateCreatorErrorWrapper>{error.message}</TemplateCreatorErrorWrapper>
            )}
            {!!error && (typeof error !== 'object' || !error.message) && (
              <TemplateCreatorErrorWrapper>
                {'Failed to create template, please try again later'}
              </TemplateCreatorErrorWrapper>
            )}
            {!error && <div></div>}
            <TemplateCreatorNextButtonWrapper>
              <Button
                icon={currentStep === 'selectTemplate' ? 'chevron-forward' : 'arrow-forward'}
                iconPosition='right'
                label={createMode === 'new' ? 'Next' : 'Create'}
                onClick={goToNextStep}
                disabled={
                  currentStep === 'enterInfo' &&
                  (!newTemplateName || (!!teams?.length && !selectedTeamIds))
                }
              />
            </TemplateCreatorNextButtonWrapper>
          </TemplateCreatorFooterRight>
        </TemplateCreatorFooter>
      )}
    </>
  )
}

export default TemplateCreatorView
