import { useState } from 'react'
import { Button, Input } from '@valuecase/ui-components'
import { UpdateTenantApiBody, UpdateTenantApiResponse } from '@valuecase/seller-backend'
import SellerApi from '../../api/SellerApi'

const updateTenant = (tenantId: string, tenantData: UpdateTenantApiBody) =>
  SellerApi.patch<UpdateTenantApiResponse, UpdateTenantApiBody, undefined>(
    `/tenants/${encodeURIComponent(tenantId)}`,
    tenantData,
  )

const UpdateTenantComponent = () => {
  const [tenantId, setTenantId] = useState<string>('')
  const [newTenantData, setTenantUpdateData] = useState(`{
    "primaryColor": "#003366"
  }`)
  const [output, setOutput] = useState('')
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        const doUpdateTenant = async () => {
          try {
            const tenantData = JSON.parse(newTenantData) as UpdateTenantApiBody
            const res = await updateTenant(tenantId, tenantData)
            setOutput(JSON.stringify(res.payload, null, 2))
          } catch (err: any) {
            setOutput('Error (see console)')
            console.error(err?.response?.data ? JSON.stringify(err?.response?.data, null, 4) : err)
          }
        }
        doUpdateTenant()
      }}
    >
      <h2>Update tenant</h2>
      <Input value={tenantId} onInput={(id) => setTenantId(id)} />
      <textarea
        style={{
          width: '100%',
        }}
        value={newTenantData}
        rows={10}
        onChange={(event) => setTenantUpdateData(event.target.value)}
      ></textarea>
      <Button label='Update tenant' submitButton={true} />
      <div>
        <pre>{output}</pre>
      </div>
    </form>
  )
}

export default UpdateTenantComponent
