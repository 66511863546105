// Must be at the top
import './utils/initPdfJs'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { WidthObserver } from '@valuecase/ui-components'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import './main.css'
import './all.css'
import './lang/i18n'
import GlobalStyle from './global-styles/GlobalStyle'
import ValuecaseDefaultTheme from './global-styles/ValuecaseDefaultTheme'
import ValuecaseThemeProvider from './global-styles/ValuecaseThemeProvider'
import { Toaster } from 'react-hot-toast'
import * as Sentry from '@sentry/react'
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  },
})

Sentry.init({
  dsn:
    import.meta.env.VITE_SENTRY_DSN ??
    'https://f62cbc1c90a84379b9f655d403a357a0@o4505148969910272.ingest.sentry.io/4505504284213248',
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'development',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^\//],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2,
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// Tell "frontend/src/utils/UsedDashboardApp.ts" in advance (so before the auth processor finishes
// and sets the correct dashboard via setUsedDashboardApp) so that everything works smoothly.
window.localStorage.setItem('valuecase-used-dashboard-app', 'v2')

window.onload = () => {
  if (import.meta.env.VITE_IFRAMELY_KEY) {
    ;(window as any).iframely_key = import.meta.env.VITE_IFRAMELY_KEY
  }
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <WidthObserver>
      {/* TODO get rid of height here, ugly code */}
      <ValuecaseThemeProvider theme={ValuecaseDefaultTheme} height='100%'>
        <GlobalStyle />
        <Toaster position='bottom-right' reverseOrder={true} gutter={12} />
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools position='bottom-left' />
        </QueryClientProvider>
      </ValuecaseThemeProvider>
    </WidthObserver>
  </React.StrictMode>,
)
