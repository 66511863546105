import { useState } from 'react'
import { Button, Input } from '@valuecase/ui-components'
import { DeleteTenantApiResponse } from '@valuecase/seller-backend'
import SellerApi from '../../api/SellerApi'

const deleteTenant = (tenantId: string) =>
  SellerApi.delete<DeleteTenantApiResponse, undefined>(`/tenants/${encodeURIComponent(tenantId)}`)

const DeleteTenantComponent = () => {
  const [tenantId, setTenantId] = useState<string>('')
  const [output, setOutput] = useState('')
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        const doDeleteTenant = async () => {
          try {
            const confirmed = await new Promise((r) => {
              const res = confirm(`Are you sure you want to DELETE tenant ${tenantId}`)
              r(res)
            })
            if (!confirmed) {
              return
            }
            const res = await deleteTenant(tenantId)
            setOutput(JSON.stringify(res.payload, null, 2))
          } catch (err) {
            setOutput('Error (see console)')
            console.error(err)
          }
        }
        doDeleteTenant()
      }}
    >
      <h2>Delete tenant</h2>
      <label>Tenant id</label>
      <Input value={tenantId} onInput={(id) => setTenantId(id)} />
      <p>Please talk with dev team to enable delete tenant functionality</p>
      <Button label='Delete tenant' submitButton={true} disabled={true} />
      <div>
        <pre>{output}</pre>
      </div>
    </form>
  )
}

export default DeleteTenantComponent
