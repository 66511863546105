import React from 'react'
import CreateTenantComponent from './CreateTenantComponent'
import DeleteTenantComponent from './DeleteTenantComponent'
import UpdateTenantComponent from './UpdateTenantComponent'
import ViewAllTenantsComponent from './ViewAllTenantsComponent'
import UploadTenantsFromBubbleComponent from './UploadTenantsFromBubbleComponent'

const TenantsAdmin = () => {
  return (
    <div
      style={{
        padding: '2em',
      }}
    >
      <h1>Tenants admin</h1>
      <ViewAllTenantsComponent />
      <hr />
      <CreateTenantComponent />
      <hr />
      <UpdateTenantComponent />
      <hr />
      <DeleteTenantComponent />
      <hr />
      <UploadTenantsFromBubbleComponent />
    </div>
  )
}

export default TenantsAdmin
