import * as React from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { cn } from '../../lib/utils'

const badgeVariants = cva('inline-flex items-center rounded font-semibold text-white', {
  variants: {
    variant: {
      neutral: 'bg-grey-s3',
      // todo: add to theme
      attention: 'bg-[#FDBA74]',
      success: 'bg-success-s4',
      info: 'bg-primary-s4',
      danger: 'bg-warning-s4',

      'subtle-neutral': 'bg-grey-s2 text-grey-s5',
      // todo: add to theme
      'subtle-attention': 'bg-[#FFEDD5] text-[#FB923C]',
      'subtle-success': 'bg-success-s2 text-success-s5',
      'subtle-info': 'bg-primary-s2 text-primary-s5',
      'subtle-danger': 'bg-warning-s2 text-warning-s5',
    },
    size: {
      default: 'px-2 py-0.5 text-xs',
      // won't use text-xxs here, because tailwind-merge will remove it
      small: 'px-1 py-0.5 text-[0.625rem] leading-[1rem]',
    },
  },
  defaultVariants: {
    variant: 'neutral',
    size: 'default',
  },
})

type BadgePropVariants = VariantProps<typeof badgeVariants>
export type BadgeVariants = BadgePropVariants['variant']

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, BadgePropVariants {}

function Badge({ className, variant, size, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant, size }), className)} {...props} />
}

export { Badge, badgeVariants }
