import { ColumnDef, Row, createColumnHelper } from '@tanstack/react-table'
import { formatForceEnglishDateWithLabel } from '@valuecase/common'
import {
  MenuDropdown,
  MenuDropdownItem,
  MenuDropdownSeparator,
  ModalControls,
  useOverlay,
} from '@valuecase/ui-components'
import { DateTime } from 'luxon'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useLDflags } from '../../../launchdarkly/Launchdarkly'
import { useTrackEvent } from '../../../mixpanel/useTrackEvent'
import ReusableTable, { ReusableTableControls } from '../../reusabletable/ReusableTable'
import HeaderCol from '../../reusabletable/header/Header.column'
import { ReadSpacesListDTO } from '../../spaces/hooks/types'
import { useCurrentTenantQuery } from '../../tenant/hooks/useReadTenant'
import TemplateCreatorModal from '../TemplateCreator/TemplateCreatorModal'
import TemplateDeleteModal from '../TemplateDelete/TemplateDeleteModal'
import TemplateEditorModal, { EditTemplateData } from '../TemplateEditor/TemplateEditorModal'
import { TemplateCreatorLibraryItemData } from '../types/TemplateCreatorLibraryItemData'
import { TemplateCreatorLibraryItemsData } from '../types/TemplateCreatorLibraryItemsData'
import { TemplateCreatorTemplateDetails } from '../types/TemplateCreatorTemplateDetails'
import {
  TemplateListIconBox,
  TemplateListNameAndDescriptionWrapper,
  TemplateListNameTextWrapper,
  TemplateListNameWrapper,
  TemplateListTableWrapper,
  TemplatelistDescriptionTextWrapper,
} from './TemplatesList.styled'
import TemplatesEmptyState from './assets/templates_empty_state.png'
import { TemplateArchivingTrigger } from './components/TemplateArchiving/TemplateArchivingTrigger'
import {
  getShowArchivedTemplatesFilterSessionStorageValue,
  getShowTeamTemplatesFilterSessionStorageValue,
  setTemplatesListSessionSettings,
} from './templateSettingsLocalStorage'
import TeamsColumn from '@/modules/teams/TeamsColumn'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import {
  PanelTabsList,
  PanelTabsTrigger,
  Tabs,
} from '@valuecase/ui-components/src/components/ui/tabs'

interface Props {
  templates: TemplateCreatorLibraryItemsData
  showCreateTemplate: (templateToSelect?: TemplateCreatorLibraryItemData) => void
}

const ProfileImage = styled.img`
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--theme-grey-s2);
  box-sizing: border-box;
  object-fit: cover;
`

const OwnerCell = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--theme-grey-s6);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

const TemplatesList: FC<Props> = ({ templates, showCreateTemplate }) => {
  const overlay = useOverlay()
  const { trackEvent } = useTrackEvent()
  const controls = useRef<ModalControls | null>(null)
  const tableControls = useRef<ReusableTableControls | null>(null)
  const columnHelper = createColumnHelper<TemplateCreatorLibraryItemData>()
  const { flags, isLoading: isLoadingFlags } = useLDflags()
  const { tenant } = useCurrentTenantQuery()
  const [showArchivedTemplates, setShowArchivedTemplates] = useState(
    getShowArchivedTemplatesFilterSessionStorageValue() ? 'true' : 'false',
  )

  const showCreateTemplateOverlay = useCallback(
    (templateToSelect?: TemplateCreatorLibraryItemData) => {
      overlay.show(
        <TemplateCreatorModal controlsRef={controls} initialSelectedTemplate={templateToSelect} />,
      )
    },
    [overlay],
  )

  const showEditTemplateOverlay = useCallback(
    (templateData: EditTemplateData) => {
      overlay.show(<TemplateEditorModal controlsRef={controls} templateData={templateData} />)
    },
    [overlay],
  )

  const showDeleteTemplateOverlay = useCallback(
    (template: TemplateCreatorTemplateDetails) => {
      overlay.show(
        <TemplateDeleteModal
          onSuccessCallback={() => {
            trackEvent({
              event: 'templates-delete-confirm',
              eventProperties: {
                indexPageName: 'Delete Template Modal',
                templateId: template?.id || 'unknownTemplateId',
              },
            })
          }}
          controlsRef={controls}
          template={template}
        />,
      )
    },
    [overlay, trackEvent],
  )

  const getTemplateUrl = useCallback((templateId: string): string => {
    const url = new URL(location.href)

    url.pathname = `/spaces/${templateId}`
    url.search = ''

    return url.href
  }, [])

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<TemplateCreatorLibraryItemData, any>[] = [
      columnHelper.accessor('title', {
        id: 'title',
        header: (h) => (
          <HeaderCol
            onClick={() => {
              trackEvent({
                event: 'templates-sort',
                eventProperties: {
                  indexPageName: 'Template List',
                  columnName: 'Template Name & Description',
                },
              })
            }}
            leftPadding={'16px'}
            headerContext={h}
            label='Template Name & Description'
          />
        ),
        cell: (ctx) => (
          // todo: replace with AvatarThumbnail
          <TemplateListNameWrapper>
            <TemplateListIconBox>
              <span>{ctx.row.original.emojiIcon || '🚀'}</span>
            </TemplateListIconBox>
            <TemplateListNameAndDescriptionWrapper>
              <TemplateListNameTextWrapper>{ctx.renderValue()}</TemplateListNameTextWrapper>
              <TemplatelistDescriptionTextWrapper>
                {ctx.row.original.description}
              </TemplatelistDescriptionTextWrapper>
            </TemplateListNameAndDescriptionWrapper>
          </TemplateListNameWrapper>
        ),
        enableSorting: true,
        sortingFn: 'alphanumeric',
      }),
      columnHelper.accessor('createdAt', {
        id: 'createdAt',
        header: (h) => (
          <HeaderCol
            onClick={() => {
              trackEvent({
                event: 'templates-sort',
                eventProperties: {
                  indexPageName: 'Template List',
                  columnName: 'Created',
                },
              })
            }}
            headerContext={h}
            label='Created'
          />
        ),
        cell: (ctx) => (
          <div className={'text-xs text-grey-s6 capitalize'}>
            {formatForceEnglishDateWithLabel(
              DateTime.fromISO(ctx.renderValue() as string),
              'short',
              tenant?.payload?.locale,
            )}
          </div>
        ),
        size: 100,
        enableSorting: true,
      }),
      columnHelper.accessor('updatedAt', {
        id: 'updatedAt',
        header: (h) => (
          <HeaderCol
            onClick={() => {
              trackEvent({
                event: 'templates-sort',
                eventProperties: {
                  indexPageName: 'Template List',
                  columnName: 'Updated',
                },
              })
            }}
            headerContext={h}
            label='Last changed'
          />
        ),
        cell: (ctx) => (
          <div className={'text-xs capitalize'} style={{ color: 'var(--theme-grey-s6)' }}>
            {formatForceEnglishDateWithLabel(
              DateTime.fromISO(ctx.renderValue() as string),
              'short',
              tenant?.payload?.locale,
            )}
          </div>
        ),
        size: 100,
        enableSorting: true,
      }),
      columnHelper.accessor('spacesCreated', {
        id: 'spacesCreated',
        header: (h) => (
          <HeaderCol
            onClick={() => {
              trackEvent({
                event: 'templates-sort',
                eventProperties: {
                  indexPageName: 'Template List',
                  columnName: 'Spaces Created',
                },
              })
            }}
            headerContext={h}
            label='Spaces Created'
          />
        ),
        cell: (ctx) => (
          <div className={'text-xs'} style={{ color: 'var(--theme-grey-s6)' }}>
            {ctx.renderValue()}
          </div>
        ),
        size: 140,
        enableSorting: true,
      }),

      columnHelper.accessor('createdByDetails', {
        id: 'createdByDetails',
        header: (h) => (
          <HeaderCol
            onClick={() => {
              trackEvent({
                event: 'templates-sort',
                eventProperties: {
                  indexPageName: 'Template List',
                  columnName: 'Created by',
                },
              })
            }}
            headerContext={h}
            label='Created by'
          />
        ),
        cell: (ctx) => (
          <OwnerCell>
            {ctx.row.original.createdByDetails.picture && (
              <ProfileImage src={ctx.row.original.createdByDetails.picture} />
            )}
            <span className={'text-xs'}>{ctx.row.original.createdByDetails?.name}</span>
          </OwnerCell>
        ),
        size: 180,
      }),
    ]

    if (flags && flags['teams-management'] === true) {
      baseColumns.push(
        columnHelper.accessor('teams', {
          id: 'teams',
          header: (h) => <HeaderCol headerContext={h} label='Teams' />,
          cell: (ctx) => <TeamsColumn teams={ctx.row.original.teams} />,
          size: 166,
          enableSorting: true,
        }),
      )
    }

    const remainingColumns = [
      columnHelper.accessor('language', {
        id: 'language',
        header: (h) => <HeaderCol headerContext={h} label='Language' />,
        cell: (ctx) => {
          return (
            <span className={'text-xs'} style={{ color: 'var(--theme-grey-s6)' }}>
              {ctx.renderValue()}
            </span>
          )
        },
        size: 134,
        enableSorting: false,
      }),
      columnHelper.accessor('archived', {
        id: 'archived',
        header: (h) => <></>,
        cell: () => {
          return <></>
        },
      }),
      columnHelper.display({
        id: 'actionColumn',
        header: (h) => <HeaderCol headerContext={h} label='' />,
        size: 66,
        cell: (ctx) => {
          return (
            <div className={'flex justify-start w-16'}>
              <div>
                <MenuDropdown
                  Trigger={
                    <Button2 leadingIcon='ellipsis-vertical' size={'small'} variant={'plain'} />
                  }
                  placement={'bottom-start'}
                >
                  <MenuDropdownItem
                    label='Open'
                    onClick={() => {
                      trackEvent({
                        event: 'templates-open-current_tab',
                        eventProperties: {
                          indexPageName: 'Templates List',
                          templateId: ctx.row.original?.rootNodeId || 'undefined',
                        },
                      }).finally(() => {
                        if (ctx.row.original.rootNodeId) {
                          window.location.href = getTemplateUrl(ctx.row.original.rootNodeId)
                        }
                      })
                    }}
                    LeftIcon={'log-in-outline'}
                    variant='default'
                  ></MenuDropdownItem>
                  <MenuDropdownItem
                    onClick={() => {
                      trackEvent({
                        event: 'templates-open-new_tab',
                        eventProperties: {
                          indexPageName: 'Templates List',
                          templateId: ctx?.row.original.rootNodeId || 'undefined',
                        },
                      })

                      if (ctx.row.original.rootNodeId) {
                        window.open(
                          getTemplateUrl(ctx.row.original.rootNodeId),
                          '_blank',
                          'noopener,noreferrer',
                        )
                      }
                    }}
                    label='Open in new tab'
                    LeftIcon={'open-outline'}
                    variant='default'
                  ></MenuDropdownItem>

                  <MenuDropdownSeparator />

                  <MenuDropdownItem
                    onClick={() => {
                      trackEvent({
                        event: '                      templates-edit-open                        ',
                        eventProperties: {
                          indexPageName: 'Templates List',
                          templateId: ctx?.row.original.id || 'undefined',
                        },
                      })
                      showEditTemplateOverlay({
                        id: ctx.row.original.id,
                        title: ctx.row.original.title,
                        description: ctx.row.original.description,
                        icon: ctx.row.original.emojiIcon,
                        rootNodeId: ctx.row.original.rootNodeId,
                        teamIds: ctx.row.original.teams.map((team) => team.id),
                      })
                    }}
                    label='Edit name & description'
                    LeftIcon={'create-outline'}
                    variant='default'
                  ></MenuDropdownItem>
                  <MenuDropdownItem
                    label='Duplicate template'
                    LeftIcon={'copy-outline'}
                    onClick={() => {
                      trackEvent({
                        event: 'templates-duplicate',
                        eventProperties: {
                          indexPageName: 'Templates List',
                          templateId: ctx?.row.original.id || 'undefined',
                        },
                      })
                      showCreateTemplate({
                        createdAt: ctx.row.original.createdAt,
                        createdBy: ctx.row.original.createdBy,
                        createdByDetails: ctx.row.original.createdByDetails,
                        id: ctx.row.original.id,
                        title: ctx.row.original.title + ' copy',
                        updatedAt: ctx.row.original.updatedAt,
                        description: ctx.row.original.description,
                        rootNodeId: ctx.row.original.rootNodeId,
                        // Duplicate icon object for immutability
                        icon: {
                          source: 'emoji-native',
                          emojiNative: ctx.row.original.emojiIcon,
                        },
                        emojiIcon: ctx.row.original.emojiIcon,
                        language: ctx.row.original.language,
                        archived: ctx.row.original.archived,
                        spacesCreated: 0,
                        teams: ctx.row.original.teams,
                      })
                    }}
                  ></MenuDropdownItem>

                  <MenuDropdownSeparator />
                  {!isLoadingFlags && flags && flags.archiving && ctx.row.original.rootNodeId && (
                    <TemplateArchivingTrigger
                      templateId={ctx.row.original.rootNodeId}
                      data={{
                        title: ctx.row.original.title,
                        description: ctx.row.original.description,
                        icon: ctx.row.original.emojiIcon || '🚀',
                      }}
                      isCurrentlyArchived={ctx.row.original.archived ?? false}
                    />
                  )}
                  <MenuDropdownItem
                    onClick={() => {
                      trackEvent({
                        event: 'templates-delete-open_modal',
                        eventProperties: {
                          indexPageName: 'Templates List',
                          templateId: ctx.row.original?.id || 'unknownTemplateId',
                        },
                      })
                      showDeleteTemplateOverlay(ctx.row.original)
                    }}
                    label='Delete Template'
                    LeftIcon={'trash-outline'}
                    variant='danger'
                  ></MenuDropdownItem>
                </MenuDropdown>
              </div>
            </div>
          )
        },
      }),
    ]

    // Concatenate all parts to form the final columns array
    return [...baseColumns, ...remainingColumns]
  }, [
    columnHelper,
    trackEvent,
    tenant?.payload?.locale,
    isLoadingFlags,
    flags,
    getTemplateUrl,
    showEditTemplateOverlay,
    showCreateTemplate,
    showDeleteTemplateOverlay,
  ])

  //Filter MySpaces/CompanySpaces
  useEffect(() => {
    tableControls.current?.setFilter({ id: 'archived', value: showArchivedTemplates === 'true' })
  }, [controls, showArchivedTemplates])

  return (
    <TemplateListTableWrapper className='flex-grow w-full block'>
      {templates.length === 0 && (
        <div className='w-full h-full flex flex-col gap-10 items-center mt-16'>
          <div className='flex flex-col gap-4 justify-center items-center'>
            <Button2 trailingIcon='add' onClick={() => showCreateTemplateOverlay()}>
              Create new template
            </Button2>
          </div>
          <div className='max-w-6xl mt-8'>
            <img className='w-full' src={TemplatesEmptyState} />
          </div>
        </div>
      )}
      {templates?.length > 0 && (
        <ReusableTable
          showOuterBorder={true}
          maxWidth={1112} // From Figma
          onRowClick={(row: Row<ReadSpacesListDTO>) => {
            trackEvent({
              event: 'templates-open-current_tab',
              eventProperties: {
                indexPageName: 'Templates List',
                templateId: row.original?.rootNodeId || 'undefined',
              },
            })
            if (row.original.rootNodeId) {
              window.location.href = getTemplateUrl(row.original.rootNodeId)
            }
          }}
          defaultSortedBasedOnColumnId={'createdAt'}
          filterBasedOnColumnId={'title'}
          data={templates}
          columns={columns}
          controls={tableControls}
          hideColumns={['archived']}
          searchPlaceholder={'Search templates...'}
          enablePagination={true}
          onPaginationClickCb={(newPageNumber: number) => {
            trackEvent({
              event: 'templates-change_page',
              eventProperties: {
                indexPageName: 'Templates List',
                templateListNewPageNumber: newPageNumber,
              },
            })
          }}
          onFinishTypingInSearchInput={() => {
            trackEvent({
              event: 'templates-search',
              eventProperties: {
                indexPageName: 'Templates List',
              },
            })
          }}
          additionalSearchRowElements={
            <>
              {!isLoadingFlags && flags && flags.archiving && (
                <Tabs
                  value={showArchivedTemplates}
                  onValueChange={(v) => {
                    setTemplatesListSessionSettings({
                      showArchivedTemplates: v === 'true',
                      showTeamTemplates: getShowTeamTemplatesFilterSessionStorageValue() ?? false,
                    })
                    setShowArchivedTemplates(v)
                  }}
                >
                  <PanelTabsList>
                    <PanelTabsTrigger value={'false'}>Active</PanelTabsTrigger>
                    <PanelTabsTrigger value={'true'}>Archived</PanelTabsTrigger>
                  </PanelTabsList>
                </Tabs>
              )}
            </>
          }
        />
      )}
    </TemplateListTableWrapper>
  )
}

export default TemplatesList
