import { useCallback, useEffect, useState } from 'react'
import { GetAllTenantsApiResponse } from '@valuecase/seller-backend'
import SellerApi from '../../api/SellerApi'

const fetchAllTenants = () => SellerApi.get<GetAllTenantsApiResponse, undefined>(`tenants`)
const fetchTenant = (tenantId: string) =>
  SellerApi.get<GetAllTenantsApiResponse, undefined>(`/tenants/${tenantId}`)

const ViewAllTenantsComponent = () => {
  const [allTenants, setAllTenants] = useState<GetAllTenantsApiResponse | null>(null)
  const [output, setOutput] = useState<string>('')
  useEffect(() => {
    const doFetchAllTenants = async () => {
      try {
        const tenants = (await fetchAllTenants()).payload
        setAllTenants(tenants)
      } catch (err) {
        setOutput('Error (see console)')
        console.error(err)
      }
    }
    doFetchAllTenants()
  }, [])
  const handleGetTenantButtonClick = useCallback((tenantId: string) => {
    const doFetchTenant = async () => {
      try {
        const tenant = (await fetchTenant(tenantId)).payload
        setOutput(JSON.stringify(tenant, null, 2))
      } catch (err) {
        setOutput('Error (see console)')
        console.error(err)
      }
    }
    doFetchTenant()
  }, [])
  return (
    <div>
      <h2>Tenants</h2>
      <ul>
        {allTenants?.map((tenant) => (
          <li key={tenant.id}>
            {tenant.tenantName}{' '}
            <button onClick={() => handleGetTenantButtonClick(tenant.id)}>Get</button>
          </li>
        ))}
      </ul>
      <div>
        <pre>{output}</pre>
      </div>
    </div>
  )
}

export default ViewAllTenantsComponent
