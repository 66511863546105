import { FC } from 'react'
import {
  TemplateCreatorFactsWrapper,
  TemplateCreatorPreviewImage,
  TemplateCreatorPreviewImageWrapper,
  TemplateCreatorTemplateFactsDescription,
  TemplateCreatorTemplateFactsHeader,
  TemplateCreatorTemplateFactsHeaderIcon,
  TemplateCreatorTemplateFactsHeaderText,
} from './TemplateCreatorParts.styled'
import { TemplateCreatorTemplateFactsProps } from '../types/TemplateCreatorTemplateFactsProps'
import blankTemplateSrc from './blank.jpg'
export const TemplateCreatorFacts: FC<TemplateCreatorTemplateFactsProps> = ({
  template,
  isAnEmptyTemplate: startFromScratch,
}) => {
  const previewImageUrl = Array.isArray(template?.previewImages) && template?.previewImages[0]?.url
  return (
    <TemplateCreatorFactsWrapper>
      {!!template && (
        <>
          {startFromScratch && (
            <TemplateCreatorPreviewImageWrapper>
              <TemplateCreatorPreviewImage src={blankTemplateSrc} />
            </TemplateCreatorPreviewImageWrapper>
          )}
          {previewImageUrl && (
            <TemplateCreatorPreviewImageWrapper>
              <TemplateCreatorPreviewImage src={previewImageUrl} />
            </TemplateCreatorPreviewImageWrapper>
          )}
          <TemplateCreatorTemplateFactsHeader>
            <TemplateCreatorTemplateFactsHeaderIcon>
              {(template.icon as unknown as string) || '🗒️'}
            </TemplateCreatorTemplateFactsHeaderIcon>{' '}
            <TemplateCreatorTemplateFactsHeaderText>
              {template?.title}
            </TemplateCreatorTemplateFactsHeaderText>
          </TemplateCreatorTemplateFactsHeader>
          <TemplateCreatorTemplateFactsDescription>
            {template.description}
          </TemplateCreatorTemplateFactsDescription>
        </>
      )}
    </TemplateCreatorFactsWrapper>
  )
}
