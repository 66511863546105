import { ValuecaseAuthState } from '@valuecase/common'
import { useAuthState } from '@/auth/auth'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import { SpaceFilterMapStatus } from './useReadSpaces'

// @TODO: Remove this after 15.04.2023 (Overtime migration)
export const STORAGE_FILTERS_KEY_DEPRECATED = 'spacesTable_columnFilters'
export const STORAGE_FILTERS_KEY = 'spacesList_columnFilters'

export type SpacesQueryFilters = {
  [key: string]: string | string[] | null | SpaceFilterMapStatus[] | undefined | boolean | Date
  spaceIds?: string[] | null
  spaceName?: string | null
  spaceType?: SpaceFilterSpaceType
  ownerIds?: string[] | null
  mapStatus?: SpaceFilterMapStatus[] | null
  templateIds?: string[] | null
  teamIds?: string[] | null
  collaboratorAccess?: boolean | null
  myTeamAccess?: boolean | null
  companyTeamAccess?: boolean | null
  updatedAfter?: Date | null
}

export type SpaceFilterSpaceType = 'all' | 'active' | 'archived' | null

const getInitialState = (userId?: string): SpacesQueryFilters => {
  const cleanedUserId = userId?.replace('bubble|', '')
  return {
    spaceName: null,
    spaceType: 'active',
    ownerIds: cleanedUserId ? [cleanedUserId] : null,
    mapStatus: null,
    templateIds: null,
  }
}

enum FilterActionTypes {
  SET_SPACE_TYPE = 'SET_SPACE_TYPE',
  SET_OWNERS = 'SET_OWNERS',
  SET_MAP_STATUS = 'SET_MAP_STATUS',
  SET_TEMPLATES = 'SET_TEMPLATES',
  SET_SPACE_NAME = 'SET_SPACE_NAME',
  SET_TEAMS_IDS = 'SET_TEAMS_IDS',
  SET_COLLABORATOR_ACCESS = 'SET_COLLABORATOR_ACCESS',
  SET_COMPANY_TEAM_ACCESS = 'SET_COMPANY_TEAM_ACCESS',
  SET_MY_TEAM_ACCESS = 'SET_MY_TEAM_ACCESS',
  RESET_FILTERS = 'RESET_FILTERS',
}

const useLocalStorage = () => {
  const deleteOldData = () => {
    const oldFilters = localStorage.getItem(STORAGE_FILTERS_KEY_DEPRECATED)
    if (oldFilters) {
      localStorage.removeItem(STORAGE_FILTERS_KEY_DEPRECATED)
    }
  }

  const setJSONValue = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  const getJSONValue = (key: string) => {
    deleteOldData() // Delete old data before fetching new data if still using old key

    const value = localStorage.getItem(key)

    return value ? JSON.parse(value) : null
  }

  return {
    setJSONValue,
    getJSONValue,
  }
}

const filterReducer = (
  state: SpacesQueryFilters,
  action: { type: FilterActionTypes; payload: any },
) => {
  switch (action.type) {
    case FilterActionTypes.SET_SPACE_TYPE:
      return { ...state, spaceType: action.payload }
    case FilterActionTypes.SET_OWNERS:
      return { ...state, ownerIds: action.payload }
    case FilterActionTypes.SET_MAP_STATUS:
      return { ...state, mapStatus: action.payload }
    case FilterActionTypes.SET_TEMPLATES:
      return { ...state, templateIds: action.payload }
    case FilterActionTypes.SET_SPACE_NAME:
      return { ...state, spaceName: action.payload }
    case FilterActionTypes.SET_TEAMS_IDS:
      return { ...state, teamIds: action.payload }
    case FilterActionTypes.SET_COLLABORATOR_ACCESS:
      return { ...state, collaboratorAccess: action.payload }
    case FilterActionTypes.SET_MY_TEAM_ACCESS:
      return { ...state, myTeamAccess: action.payload }
    case FilterActionTypes.SET_COMPANY_TEAM_ACCESS:
      return { ...state, companyTeamAccess: action.payload }
    case FilterActionTypes.RESET_FILTERS:
      return { ...action.payload }
    default:
      return state
  }
}

type UseSpaceFilteringReturnType = {
  filters: SpacesQueryFilters
  spaceName: SpacesQueryFilters['spaceName']
  spaceType: SpacesQueryFilters['spaceType']
  ownerIds: SpacesQueryFilters['ownerIds']
  mapStatus: SpacesQueryFilters['mapStatus']
  templateIds: SpacesQueryFilters['templateIds']
  teamIds?: SpacesQueryFilters['teamIds']
  collaboratorAccess?: SpacesQueryFilters['collaboratorAccess']
  myTeamAccess?: SpacesQueryFilters['collaboratorAccess']
  companyTeamAccess?: SpacesQueryFilters['companyTeamAccess']
  setSpaceType: (value: SpacesQueryFilters['spaceType']) => void
  setOwners: (value: SpacesQueryFilters['ownerIds']) => void
  setMapStatus: (value: SpacesQueryFilters['mapStatus']) => void
  setTemplates: (value: SpacesQueryFilters['templateIds']) => void
  setSpaceName: (value: SpacesQueryFilters['spaceName']) => void
  setTeamIds?: (value: SpacesQueryFilters['teamIds']) => void
  setCollaboratorAccess?: (value: SpacesQueryFilters['collaboratorAccess']) => void
  setMyTeamAccess?: (value: SpacesQueryFilters['collaboratorAccess']) => void
  setCompanyTeamAccess?: (value: SpacesQueryFilters['companyTeamAccess']) => void
  resetFilters: () => void
}

const useSpaceFiltering = (): UseSpaceFilteringReturnType => {
  const storage = useLocalStorage()
  const auth = useAuthState() as ValuecaseAuthState
  const storedFilters = useMemo(() => storage.getJSONValue(STORAGE_FILTERS_KEY), [storage])
  const initialState = useMemo(
    () => (auth.authorized ? getInitialState(auth.sub) : getInitialState()),
    [auth],
  )

  const [state, dispatch] = useReducer(
    filterReducer,
    (storedFilters as SpacesQueryFilters) || initialState,
  )

  useEffect(() => {
    if (state) {
      storage.setJSONValue(STORAGE_FILTERS_KEY, state)
    }
  }, [state, storage])

  const setSpaceType = useCallback(
    (value: SpacesQueryFilters['spaceType']) =>
      dispatch({ type: FilterActionTypes.SET_SPACE_TYPE, payload: value }),
    [dispatch],
  )

  const setOwners = useCallback(
    (value: SpacesQueryFilters['ownerIds']) =>
      dispatch({ type: FilterActionTypes.SET_OWNERS, payload: value }),
    [dispatch],
  )

  const setMapStatus = useCallback(
    (value: SpacesQueryFilters['mapStatus']) =>
      dispatch({ type: FilterActionTypes.SET_MAP_STATUS, payload: value }),
    [dispatch],
  )

  const setTemplates = useCallback(
    (value: SpacesQueryFilters['templateIds']) =>
      dispatch({ type: FilterActionTypes.SET_TEMPLATES, payload: value }),
    [dispatch],
  )

  const setSpaceName = useCallback(
    (value: SpacesQueryFilters['spaceName']) =>
      dispatch({ type: FilterActionTypes.SET_SPACE_NAME, payload: value }),
    [dispatch],
  )

  const setTeamIds = useCallback(
    (value: SpacesQueryFilters['teamIds']) =>
      dispatch({ type: FilterActionTypes.SET_TEAMS_IDS, payload: value }),
    [dispatch],
  )

  const setCollaboratorAccess = useCallback(
    (value: SpacesQueryFilters['collaboratorAccess']) =>
      dispatch({ type: FilterActionTypes.SET_COLLABORATOR_ACCESS, payload: value }),
    [dispatch],
  )

  const setMyTeamAccess = useCallback(
    (value: SpacesQueryFilters['collaboratorAccess']) =>
      dispatch({ type: FilterActionTypes.SET_MY_TEAM_ACCESS, payload: value }),
    [dispatch],
  )

  const setCompanyTeamAccess = useCallback(
    (value: SpacesQueryFilters['companyTeamAccess']) =>
      dispatch({ type: FilterActionTypes.SET_COMPANY_TEAM_ACCESS, payload: value }),
    [dispatch],
  )

  const resetFilters = useCallback(
    () => dispatch({ type: FilterActionTypes.RESET_FILTERS, payload: initialState }),
    [initialState, dispatch],
  )

  const spaceName = useMemo(() => state.spaceName, [state.spaceName])
  const spaceType = useMemo(() => state.spaceType, [state.spaceType])
  const owners = useMemo(() => state.ownerIds, [state.ownerIds])
  const mapStatus = useMemo(() => state.mapStatus, [state.mapStatus])
  const templates = useMemo(() => state.templateIds, [state.templateIds])
  const teamIds = useMemo(() => state.teamIds, [state.teamIds])
  const collaboratorAccess = useMemo(() => state.collaboratorAccess, [state.collaboratorAccess])
  const myTeamAccess = useMemo(() => state.myTeamAccess, [state.myTeamAccess])
  const companyTeamAccess = useMemo(() => state.companyTeamAccess, [state.companyTeamAccess])

  return {
    spaceName,
    spaceType,
    ownerIds: owners,
    mapStatus,
    templateIds: templates,
    teamIds,
    collaboratorAccess,
    myTeamAccess,
    companyTeamAccess,
    filters: state,
    setSpaceType,
    setOwners,
    setMapStatus,
    setTemplates,
    setSpaceName,
    setTeamIds: setTeamIds,
    setCollaboratorAccess,
    setMyTeamAccess,
    setCompanyTeamAccess,
    resetFilters,
  }
}

export const SpaceFilterContext = createContext<any>(null)

export const SpaceFilterProvider = ({ children }: { children: ReactNode }) => {
  const filterHook = useSpaceFiltering()

  return <SpaceFilterContext.Provider value={filterHook}>{children}</SpaceFilterContext.Provider>
}

export const useSpaceFilter = (): UseSpaceFilteringReturnType => {
  const context = useContext(SpaceFilterContext)
  if (!context) {
    throw new Error('useSpaceFilter must be used within a SpaceFilterProvider')
  }
  return context
}
