import { User } from '@valuecase/common'
import {
  Command,
  CommandCheckboxItem,
  CommandEmpty,
  CommandInput,
  CommandItemCheckboxDetails,
  CommandList,
  InitialsAvatar,
  InitialsAvatarSize,
} from '@valuecase/ui-components'
import { Chip, ChipClose, ChipLabel } from '@valuecase/ui-components/src/components/ui/chip'
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateHeader,
  EmptyStateTitle,
} from '@valuecase/ui-components/src/components/ui/empty'

type Props = {
  users: User[]
  toggleMember: (member: User) => void
  selectedMembers: Set<User>
}

const UserSelection = ({ users, selectedMembers, toggleMember }: Props) => {
  return (
    <>
      {users?.length === 0 && (
        <EmptyState icon={'american-football'} iconSize={'small'} className={'py-4'}>
          <EmptyStateHeader>
            <EmptyStateTitle>No users</EmptyStateTitle>
            <EmptyStateDescription>
              There are no users in your organization yet.
            </EmptyStateDescription>
          </EmptyStateHeader>
        </EmptyState>
      )}
      <div className={'flex flex-wrap gap-2 max-h-32 overflow-y-auto'}>
        {[...selectedMembers].map((member) => {
          return (
            <Chip key={member.id}>
              <InitialsAvatar
                size={InitialsAvatarSize.EXTRA_SMALL}
                text={member.fullName ?? 'N/A'}
                isSeller={true}
              />
              <ChipLabel>{member.fullName}</ChipLabel>
              <ChipClose onClick={() => toggleMember(member)} />
            </Chip>
          )
        })}
      </div>

      {users && users.length > 0 && (
        <Command>
          <CommandInput placeholder={'Search members...'} />
          <div className='h-64 overflow-y-auto'>
            <CommandList className='h-full'>
              <CommandEmpty>
                <EmptyState>
                  <EmptyStateHeader>
                    <EmptyStateTitle>Nothing found!</EmptyStateTitle>
                    <EmptyStateDescription>
                      You might want to check for typos or try another name.
                    </EmptyStateDescription>
                  </EmptyStateHeader>
                </EmptyState>
              </CommandEmpty>
              {[...users].map((user) => {
                return (
                  <CommandCheckboxItem
                    key={user.id}
                    selected={selectedMembers.has(user)}
                    onSelect={() => toggleMember(user)}
                  >
                    <InitialsAvatar
                      text={user.fullName ?? 'N/A'}
                      size={InitialsAvatarSize.SMALL}
                      isSeller={true}
                    />
                    <CommandItemCheckboxDetails
                      name={user.fullName ?? 'N/A'}
                      subtitle={user.email}
                    />
                  </CommandCheckboxItem>
                )
              })}
            </CommandList>
          </div>
        </Command>
      )}
    </>
  )
}

export default UserSelection
