import { SidebarItemStructure } from '../types.nav'
import { useEffect, useMemo, useState } from 'react'
import Badges from '../components/Badges/BadgesContainer'
import { useNavigate } from '@tanstack/react-router'
import { useHasPermission } from '../../../../auth/permissions'
import { useActivityFeedCount } from '../../../activityFeed/hooks/useActivityFeed'
import { ActivityBadge } from '../components/Badges/ActivityBadge'
import { useLDflags } from '../../../../launchdarkly/Launchdarkly'
import HomeBadge from '@/modules/navigation/sidebar/components/Badges/HomeBadge'

export function useSidebarItemStructure(): Array<SidebarItemStructure> {
  const nav = useNavigate()
  const { flags } = useLDflags()
  const canManageTemplates = useHasPermission('TEMPLATES_MANAGE')
  const { unreadCount } = useActivityFeedCount()
  const [unreadActivitiesCount, setUnreadActivitiesCount] = useState(0)

  const hasUserManagePermission = useHasPermission('USERS_MANAGE')
  const hasTenantSettingsPermission = useHasPermission('TENANT_SETTINGS_MANAGE')

  const hasAssetManagementPermission = useHasPermission('ASSET_LIBRARY_MANAGE')

  useEffect(() => {
    if (!unreadCount) {
      return
    }
    setUnreadActivitiesCount(unreadCount.unreadActivitiesCount)
  }, [unreadCount])

  return useMemo<Array<SidebarItemStructure>>(() => {
    const sidebar: Array<SidebarItemStructure> = []
    sidebar.push({
      label: (
        <div className={'flex gap-2 items-center'}>
          <span>Spaces</span>
          <Badges>
            <HomeBadge />
          </Badges>
        </div>
      ),
      redirectTo: () => nav({ to: '/spaces' }),
      location: 'spaces',
      id: 'spaces',
      iconName: 'documents',
    })
    if (canManageTemplates) {
      sidebar.push({
        label: 'Templates',
        iconName: 'grid',
        redirectTo: () => nav({ to: '/templates' }),
        location: 'templates',
        id: 'templates',
      })
    }
    if (flags && flags['task-management']) {
      sidebar.push({
        label: 'Tasks',
        iconName: 'checkbox',
        location: null,
        id: 'tasks',
        redirectTo: () => nav({ to: '/tasks' }),
        childrenItems: [
          {
            label: 'Dashboard',
            redirectTo: () => nav({ to: '/tasks' }),
            location: 'taskDashboard',
            id: 'taskDashboard',
          },
          {
            label: 'List',
            redirectTo: () => nav({ to: '/tasks/list' }),
            location: 'taskList',
            id: 'taskList',
          },
        ],
      })
    }
    if (hasAssetManagementPermission && flags && flags['asset-management']) {
      sidebar.push({
        label: 'Asset Library',
        iconName: 'folder-open',
        redirectTo: () => nav({ to: '/assetLibrary' }),
        location: 'assetLibrary',
        id: 'assetLibrary',
      })
    }
    sidebar.push({
      label: 'Activity Feed',
      iconName: 'list',
      redirectTo: () => nav({ to: '/activityFeed' }),
      location: 'activityFeed',
      id: 'activityFeed',
      badges: (
        <Badges>
          <ActivityBadge count={unreadActivitiesCount} />
        </Badges>
      ),
    })
    if (flags && flags['dashboard-analytics-page'] && hasTenantSettingsPermission) {
      sidebar.push({
        label: 'Analytics',
        iconName: 'bar-chart',
        redirectTo: () => nav({ to: '/analytics' }),
        location: 'analytics',
        id: 'analytics',
      })
    }
    sidebar.push({
      label: 'My Settings',
      iconName: 'person-circle',
      location: null,
      id: 'mySettings',
      redirectTo: () => nav({ to: '/mySettings/profileAccount' }),
      childrenItems: [
        {
          label: 'Profile Account',
          redirectTo: () => nav({ to: '/mySettings/profileAccount' }),
          location: 'profileAccount',
          id: 'profileAccount',
        },
        {
          label: 'Mail Notifications',
          redirectTo: () => nav({ to: '/mySettings/mailNotifications' }),
          location: 'mailNotifications',
          id: 'mailNotifications',
        },
        {
          label: 'Integrations',
          redirectTo: () => nav({ to: '/mySettings/integrations' }),
          location: 'integrations',
          id: 'integrations',
        },
      ],
    })

    if (hasUserManagePermission || hasTenantSettingsPermission) {
      const childrenItems = []
      if (hasTenantSettingsPermission) {
        childrenItems.push({
          label: 'Company Settings',
          redirectTo: () => nav({ to: '/administration/companySettings' }),
          location: 'companySettings',
          id: 'companySettings',
        })
      }
      if (hasUserManagePermission) {
        childrenItems.push({
          label: 'User Management',
          redirectTo: () => nav({ to: '/administration/userManagement' }),
          location: 'userManagement',
          id: 'userManagement',
        })
      }
      if (hasTenantSettingsPermission && flags && flags['teams-management']) {
        childrenItems.push({
          label: 'Team Management',
          redirectTo: () => nav({ to: '/administration/teamManagement' }),
          location: 'teamManagement',
          id: 'teamManagement',
        })
      }

      if (flags && flags['space-custom-properties'] && hasTenantSettingsPermission) {
        childrenItems.push({
          label: 'Space Properties',
          redirectTo: () => nav({ to: '/administration/spaceProperties' }),
          location: 'spaceProperties',
          id: 'spaceProperties',
        })
      }
      sidebar.push({
        label: 'Administration',
        iconName: 'settings',
        location: null,
        id: 'administration',
        redirectTo: () => nav({ to: '/administration/companySettings' }),
        childrenItems: childrenItems,
      })
    }

    return sidebar
  }, [
    canManageTemplates,
    hasAssetManagementPermission,
    flags,
    unreadActivitiesCount,
    hasTenantSettingsPermission,
    hasUserManagePermission,
    nav,
  ])
}
