import { useEffect, useState } from 'react'
import { BackgroundImageSetting } from './BackgroundImageSetting'
import { LogoSetting } from './LogoSetting'
import { PrimaryColorSetting } from './PrimaryColorSetting'
import { PrivacyPolicyImprintSetting } from './PrivacyPolicyImprintSetting'
import { SubdomainSetting } from './SubdomainSetting'
import { ThemeSetting } from './ThemeSetting'
import SpacePreview from './interactiveSpacePreview/SpacePreview'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { BuyerIntercomSetting } from './BuyerIntercomSettings'
import EngagementScoreSettings from './EngagementScoreSettings'
import { FontSetting } from '@/modules/companySettings/FontSetting'
import { FaviconSetting } from '@/modules/companySettings/FaviconSetting'
import AiWritingAssistantSettings from './AiWritingAssistantSettings'

function Divider() {
  return <div className='flex h-1 border-b border-grey-s3 w-full'></div>
}

const CompanySettings = () => {
  const [mixpanel_pageVisitedTracked, set_mixpanel_pageVisitedTracked] = useState(false)
  const { trackEvent } = useTrackEvent()
  const { flags } = useLDflags()

  //mixpanel track  page visit
  useEffect(() => {
    if (!mixpanel_pageVisitedTracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'Company Settings page',
        },
      }).finally(() => {
        set_mixpanel_pageVisitedTracked(true)
      })
    }
  }, [mixpanel_pageVisitedTracked, trackEvent, set_mixpanel_pageVisitedTracked])

  return (
    <div className='flex flex-col py-11 w-full'>
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-col w-140'}>
          <h1 className={'text-2xl font-bold'}>Company Settings</h1>
          <p className={'text-sm text-grey-s5 mt-2 leading-6'}>
            Make your spaces truly your own and customize them to reflect your company&apos;s look.
            Changes made here are automatically reflected across all your spaces and templates.
          </p>
        </div>
      </div>
      <div className='w-full border-grey-s2 border-t-[1px] mt-10' />

      <div className={'flex gap-16 flex-col'}>
        <div className='flex justify-between gap-4 mt-10'>
          <div className='flex w-2/3 flex-col gap-16 pr-10'>
            <LogoSetting />
            <BackgroundImageSetting />
            {flags && flags['favicon'] && <FaviconSetting />}
            <PrimaryColorSetting />
            <ThemeSetting />
            <FontSetting />
          </div>
          <SpacePreview />
        </div>

        <Divider />

        <div className='flex w-8/12 flex-col gap-16 pb-20 pr-14'>
          <SubdomainSetting />
          <EngagementScoreSettings />
          {flags?.['ai-writing-assistant'] && <AiWritingAssistantSettings />}
          {flags?.['buyer-intercom-integration'] && <BuyerIntercomSetting />}
          <PrivacyPolicyImprintSetting />
        </div>
      </div>
    </div>
  )
}

export default CompanySettings
