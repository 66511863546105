import * as pdfjs from 'pdfjs-dist'
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

// Preload pdfjs worker
window
  .fetch(pdfjs.GlobalWorkerOptions.workerSrc)
  .then(() => {
    console.log('📑 Preloading pdf.js worker succeeded ✅')
  })
  .catch(() => {
    console.log('📑 Preloading pdf.js worker failed ❌')
  })
