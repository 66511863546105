import Topbar from '../topbar/Topbar'
import Sidebar from '../sidebar/Sidebar'
import { S } from './Layout.styled'
import { Outlet } from '@tanstack/react-router'
import IntercomComponent from '../Intercom/Intercom'

const Layout = () => {
  return (
    <S.Layout>
      <Topbar />
      <S.SecondRow>
        <Sidebar />
        <S.Main>
          <S.Margin>
            <Outlet />
          </S.Margin>
        </S.Main>
      </S.SecondRow>
      <IntercomComponent />
    </S.Layout>
  )
}

export default Layout

//Please Don't delete this for now, we might need it :)
// const [isSupportedScreen, set_isSupportedScreen] = useState<boolean | null>(null)
//   const layoutChangedCallback = (matches: boolean) => {
//     if (matches) {
//       //i m on a wide enough screen
//       set_isSupportedScreen(true)
//     } else {
//       //i m on a way too small screen
//       set_isSupportedScreen(false)
//     }
//   }

//   useEffect(() => {
//     // As we currently do not support small screens.
//     // Instead of showing a bad layout, we tell the user that our app is not usable in small screens yet.
//     // This saves us a lot of dev effort and speed.
//     //We do this in this effect👇🏻

//     // set media query breakpoint  //read about matchMedia here ttps://www.cocomore.com/blog/dont-use-window-onresize
//     const breakpoint = window.matchMedia('(min-width: 700px)') //For reference 700px is close to an ipad screen(768px)

//     // set listener to run callback
//     breakpoint.addEventListener('change', (e) => layoutChangedCallback(e.matches))

//     layoutChangedCallback(breakpoint.matches)
//   })
