import styled from 'styled-components'
import { SelectList } from '../../../../ui-components/SelectList'
import { TemplateCreatorLibraryItemData } from '../../../templates/types/TemplateCreatorLibraryItemData'
import { TemplateCreatorLibraryItemsData } from '../../../templates/types/TemplateCreatorLibraryItemsData'
import { Hairline } from '@valuecase/ui-components'
import { formatDateShort } from '@valuecase/common'
import { DateTime } from 'luxon'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'

const ContainerWrapper = styled.div`
  width: calc(100% + 68px);
  margin-left: -36px;
  height: 406px;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 406px;

  .left-side {
    background: ${({ theme }) => theme.grey.s1};
    height: 100%;
    border-right: 1px solid ${({ theme }) => theme.grey.s2};
    padding: 0 12px;
    overflow-y: auto;

    .templates-list-container {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .template-entry-container {
        min-height: 49px;
        padding: 8px 12px;
        width: 100%;
        cursor: pointer;
        border-radius: 16px;
        display: flex;
        gap: 8px;
        align-items: center;
        border: 1px solid transparent;

        &:hover {
          background: ${({ theme }) => theme.primary.s3};
        }

        p {
          margin: 0;
          padding: 0;
        }

        .emoji {
          font-size: 24px;
        }
        .title {
          font-weight: 700;
          font-size: 14px;
          color: ${({ theme }) => theme.grey.s6};
        }
      }
      .selected {
        border-color: ${({ theme }) => theme.grey.s2};
      }
    }
  }

  .right-side {
    flex: 1;
    height: 100%;
    padding: 24px 32px;

    .selected-template-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h3 {
        font-size: 14px;
        font-weight: 700;
        color: ${({ theme }) => theme.grey.s6};
        margin-top: 0;
        margin-bottom: 8px;
      }

      p {
        font-size: 14px;
        color: ${({ theme }) => theme.grey.s5};
        margin: 0;
      }

      .template-attributes-container {
        display: flex;
        gap: 8px;

        .template-attribute {
          background: ${({ theme }) => theme.primary.s2};
          padding: 6px 7px;
          border-radius: 8px;

          p {
            color: ${({ theme }) => theme.primary.s4};
            font-size: 10px;
            font-weight: 750;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
`

type Step2Props = {
  onSelectTemplate: (templateId: string) => void
  templates: TemplateCreatorLibraryItemsData | undefined
  selectedTemplate: TemplateCreatorLibraryItemData | undefined
}

export function Step2({ templates, onSelectTemplate, selectedTemplate }: Step2Props) {
  const selectedTemplateId = selectedTemplate?.rootNodeId
  const { tenant } = useCurrentTenantQuery()

  return (
    <ContainerWrapper>
      <Hairline />
      <Container>
        <div className='left-side'>
          <div
            data-intercom-target='Spaces Creation Template Selection List'
            className='templates-list-container'
          >
            {Array.isArray(templates) && (
              <SelectList
                selectedId={selectedTemplateId}
                selectItem={onSelectTemplate}
                items={templates.map((template) => ({
                  id: template.rootNodeId,
                  icon: template.emojiIcon
                    ? {
                        source: 'emoji-string',
                        emojiString: template.emojiIcon,
                      }
                    : {
                        source: 'emoji-string',
                        emojiString: '🚀',
                      },
                  text: template.title,
                }))}
              />
            )}
          </div>
        </div>

        <div className='right-side'>
          {selectedTemplate && (
            <div className='selected-template-container'>
              <div>
                <h3>{selectedTemplate?.title}</h3>
                <p>{selectedTemplate?.description || 'This template has no set description'}</p>
              </div>
              <div className='template-attributes-container'>
                <div className='template-attribute'>
                  <p>
                    created{' '}
                    {formatDateShort(
                      DateTime.fromISO(selectedTemplate.createdAt),
                      tenant?.payload?.locale,
                    )}
                  </p>
                </div>

                <div className='template-attribute'>
                  <p>{selectedTemplate.language || 'English'}</p>
                </div>

                <div className='template-attribute'>
                  <p>
                    {selectedTemplate.spacesCreated} Space
                    {selectedTemplate.spacesCreated !== 1 ? 's' : ''}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
      <Hairline />
    </ContainerWrapper>
  )
}
