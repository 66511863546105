import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from '../../lib/utils'

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn('inline-flex h-10 items-center justify-center rounded-md p-1 gap-1', className)}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'inline-flex items-center justify-center whitespace-nowrap rounded-sm p-3 text-xs shadow-none',
      'font-medium transition-all outline-none text-grey-s6 border-b-2 border-transparent border-solid',
      'disabled:pointer-events-none disabled:opacity-50 hover:text-primary-s5',
      'data-[state=active]:text-primary-s5 data-[state=active]:border-primary-s5',
      className,
    )}
    {...props}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const PanelTabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, children, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn('inline-flex h-10 items-center justify-center rounded-md', className)}
    {...props}
  >
    {children}
  </TabsPrimitive.List>
))

PanelTabsList.displayName = TabsPrimitive.List.displayName

const PanelTabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'inline-flex items-center justify-center whitespace-nowrap px-3 h-9 text-xs shadow-none font-semibold transition-all outline-none border-r-[1px] border-t-[1px] border-b-[1px] border-grey-s2 bg-white',
      'data-[state=active]:bg-grey-s1 data-[state=active]:text-grey-s6',
      'first:rounded-l-md first:border-l-[1px]',
      'last:rounded-r-md last:border-r-[1px]',
      'disabled:pointer-events-none disabled:opacity-50 hover:text-grey-s6',
      'text-grey-s5',
      className,
    )}
    {...props}
  />
))

PanelTabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn('mt-2 focus-visible:outline-none', className)}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent, PanelTabsList, PanelTabsTrigger }
