import { FC, ReactNode, useState } from 'react'
import { S } from './SidebarItem.styled'
import { useTheme } from 'styled-components'
import SidebarItemChild from './SidebarItemChild/SidebarItemChild'
import { IonIcon } from '@valuecase/ui-components'
import { SidebarItemStructure } from '../../types.nav'

interface Props {
  isActive: boolean
  iconName?: string
  label: string | ReactNode
  subItems?: SidebarItemStructure[] | undefined
  onClick?: () => void
  badge?: ReactNode
  currentLocation?: string
}
const SidebarItem: FC<Props> = (props) => {
  const theme = useTheme()
  const [isBeingHovered, set_isBeingHovered] = useState<boolean>(false)

  return (
    <S.RootContainer>
      <S.SidebarItem
        className={'transition-all'}
        onMouseEnter={() => {
          set_isBeingHovered(true)
        }}
        onMouseLeave={() => {
          set_isBeingHovered(false)
        }}
        onClick={props.onClick}
        $isHighlighted={props.isActive}
      >
        <S.Container className={'w-full'}>
          <div className={'flex justify-between w-full'}>
            <div className={'flex'}>
              {props.iconName && (
                <IonIcon
                  name={
                    props.isActive
                      ? props.iconName
                      : isBeingHovered
                        ? `${props.iconName}-outline`
                        : `${props.iconName}-outline`
                  }
                  style={{
                    fontSize: '20px',
                    color: props.isActive
                      ? theme.primary.s5
                      : isBeingHovered
                        ? theme.primary.s5
                        : theme.grey.s3,
                  }}
                />
              )}
              <S.Label> {props.label}</S.Label>
            </div>
            {props.badge && <>{props.badge}</>}
          </div>
        </S.Container>
      </S.SidebarItem>
      {props.isActive && props.subItems && (
        <S.SidebarItemChildren>
          {props.subItems.map((c) => (
            <SidebarItemChild
              label={c.label}
              isActive={props.currentLocation === c.location}
              onClick={() => {
                if (c.redirectTo) {
                  c.redirectTo()
                }
              }}
              key={c.id}
            />
          ))}
        </S.SidebarItemChildren>
      )}
    </S.RootContainer>
  )
}

export default SidebarItem
