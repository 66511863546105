// import 'react-datepicker/dist/react-datepicker.css'
import DatePickerLibrary from 'react-datepicker'

import { registerLocale } from 'react-datepicker'
import { enGB } from 'date-fns/locale/en-GB'
import { IonIcon } from '../../../../Icons/IonIcon'
import classNames from 'classnames'
import styled from 'styled-components'
registerLocale('en-GB', enGB)

export interface DatePickerProps {
  date?: Date | null
  onChange?: (newDate: Date | null) => void
  shadow?: boolean
}

export function Datepicker({ date = null, onChange, shadow = false }: DatePickerProps) {
  return (
    <DatePickerStyles
      className={classNames({
        shadow,
      })}
    >
      <DatePickerLibrary
        locale='en-GB'
        selected={date}
        onChange={(c) => {
          onChange?.(c)
        }}
        inline
        previousMonthButtonLabel={<IonIcon name='chevron-back-outline' />}
        nextMonthButtonLabel={<IonIcon name='chevron-forward-outline' />}
      />
    </DatePickerStyles>
  )
}

const DatePickerStyles = styled.span`
  display: inline-block;
  border-radius: 8px;
  border: 1px solid var(--theme-grey-s2);
  background: var(--theme-light);
  padding: 16px;

  &.shadow {
    box-shadow:
      0px 6px 16px -4px rgba(0, 0, 0, 0.1),
      0px 2px 6px -2px rgba(0, 0, 0, 0.06);
  }

  .react-datepicker {
    position: relative;

    // https://github.com/Hacker0x01/react-datepicker/issues/3924#issuecomment-1420795802
    .react-datepicker__aria-live {
      position: absolute;
      clip-path: circle(0);
      border: 0;
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      width: 1px;
      white-space: nowrap;
    }

    button.react-datepicker__navigation {
      position: absolute;

      border: none;
      outline: none;
      background: transparent;
      cursor: pointer;
      border-radius: 8px;
      padding: 2px;

      width: 24px;
      height: 24px;

      display: flex;
      justify-content: center;
      align-items: center;

      .react-datepicker__navigation-icon {
        height: 24px;
        width: 24px;

        display: flex;
        justify-content: center;
        align-items: center;

        .ionicon {
          color: var(--theme-grey-s4);
        }
      }

      &:hover {
        background: var(--theme-grey-s1);
      }
    }

    button.react-datepicker__navigation--previous {
      top: 0;
      left: 0;
    }

    button.react-datepicker__navigation--next {
      top: 0;
      right: 0;
    }
  }

  .react-datepicker__month-container {
    .react-datepicker__header {
      border-radius: 0;
      background: var(--theme-light);

      .react-datepicker__current-month {
        display: block;
        text-align: center;

        font-weight: 600;
        font-size: 12px;
        line-height: 21px;
        color: var(--theme-grey-s6);

        margin-bottom: 16px;
      }

      .react-datepicker__day-names {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        margin-bottom: 10px;

        .react-datepicker__day-name {
          width: 24px;
          height: 24px;
          text-align: center;
          color: var(--theme-grey-s4);
        }
      }
    }
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .react-datepicker__week {
      display: flex;
      flex-direction: row;
      column-gap: 10px;

      .react-datepicker__day {
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
        color: var(--theme-grey-s6);

        transition:
          color 0.15s ease,
          background 0.15s ease;

        &:hover {
          background: var(--theme-primary-s2);
        }

        &.react-datepicker__day--today {
          background: var(--theme-primary-s2);
        }

        &.react-datepicker__day--selected {
          background: var(--theme-primary-s6);
          color: white;
        }
      }

      .react-datepicker__day--weekend {
      }

      .react-datepicker__day--outside-month {
        color: var(--theme-grey-s4);
      }
    }
  }
`
