import { ReactNode, useCallback, useState } from 'react'
import { IonIcon } from '../../Icons/IonIcon'
import { cn } from '../../lib'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTrigger,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input2,
  OneLineTruncatedText,
  useNotifications,
} from '../../components/ui'
import { Button2 } from '../../components/ui/button'
import { useMutateAssetLibraryFolder } from './hooks'
import { TAssetLibraryFolder } from '@valuecase/common'

export const AssetFolderButton = ({
  folder,
  onClick,
  showActions,
}: {
  folder: TAssetLibraryFolder
  onClick?: () => void
  showActions?: boolean
}) => {
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)
  const [hasOpenDialog, setHasOpenDialog] = useState(false)

  const handleDialogItemOpenChange = useCallback((open: boolean) => {
    setHasOpenDialog(open)
    if (!open) {
      setDropdownMenuOpen(false)
    }
  }, [])

  return (
    <div
      role='button'
      className={cn(
        'group hover:shadow transition-shadow',
        'bg-white rounded-lg text-left flex justify-between p-2 text-xs font-semibold min-h-10 items-center gap-2',
      )}
      onClick={onClick}
    >
      <div className={'flex gap-2 items-center'}>
        <IonIcon
          name='folder-open'
          className='text-grey-s3 w-5 h-5 group-hover:text-grey-s4 transition-colors'
        />
        <OneLineTruncatedText>{folder.name}</OneLineTruncatedText>
      </div>
      {showActions && (
        <DropdownMenu
          open={dropdownMenuOpen}
          onOpenChange={(open) => {
            setDropdownMenuOpen(open)
          }}
          modal={false}
        >
          <DropdownMenuTrigger asChild>
            <Button2
              size={'small'}
              variant={'plain'}
              leadingIcon={'ellipsis-vertical'}
              onClick={(e) => {
                e.stopPropagation()
              }}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align={'end'}
            onClick={(e) => {
              e.stopPropagation()
            }}
            className={cn({
              hidden: hasOpenDialog || !dropdownMenuOpen,
            })}
          >
            <RenameFolderDialog folder={folder} onOpenChange={handleDialogItemOpenChange}>
              <DropdownMenuItem
                onSelect={(event) => {
                  event.preventDefault()
                }}
              >
                <div className={'flex gap-2 items-center'}>
                  <IonIcon name={'c_rename'} className={'w-4 h-4 text-grey-s4'} />
                  Rename
                </div>
              </DropdownMenuItem>
            </RenameFolderDialog>
            <DeleteFolderDialog folderId={folder.id} onOpenChange={handleDialogItemOpenChange}>
              <DropdownMenuItem
                onSelect={(event) => {
                  event.preventDefault()
                }}
              >
                <div className={'flex gap-2 items-center'}>
                  <IonIcon name={'trash-outline'} className={'w-4 h-4 text-warning-s4'} />
                  <p className={'text-warning-s5'}>Delete</p>
                </div>
              </DropdownMenuItem>
            </DeleteFolderDialog>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  )
}

export const AddNewFolderDialog = ({
  parentFolderId,
  open,
  onOpenChange,
}: {
  parentFolderId: string | null
  open: boolean
  onOpenChange?: (open: boolean) => void
}) => {
  const { createFolder } = useMutateAssetLibraryFolder()
  const { success, error } = useNotifications()

  const [newFolderName, setNewFolderName] = useState('')

  const create = useCallback(async () => {
    if (!newFolderName) {
      return
    }
    onOpenChange?.(false)
    try {
      await createFolder({
        name: newFolderName.trim(),
        parentFolderId,
      })
      setNewFolderName('')
      success('Folder created successfully')
    } catch (e) {
      console.error(e)
      error('Failed to create folder')
    }
  }, [newFolderName, createFolder, parentFolderId, onOpenChange, success, error])

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        onOpenChange?.(open)
        if (!open) {
          setNewFolderName('')
        }
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Folder</DialogTitle>
        </DialogHeader>

        <form
          onSubmit={(e) => {
            e.preventDefault()
            create()
          }}
        >
          <div className='flex flex-col gap-2'>
            <label htmlFor='folderName' className={'text-xs text-grey-s5'}>
              Folder Name
            </label>
            <Input2
              id='folderName'
              type='text'
              name='folderName'
              value={newFolderName}
              onInput={(e) => setNewFolderName(e.currentTarget.value)}
            />
          </div>
        </form>

        <div className={'flex justify-end gap-3'}>
          <Button2
            variant={'plain'}
            onClick={() => {
              onOpenChange?.(false)
              setNewFolderName('')
            }}
          >
            Cancel
          </Button2>
          <Button2
            disabled={!newFolderName}
            type={'submit'}
            leadingIcon={'checkmark'}
            variant={'solid'}
            onClick={create}
          >
            Add Folder
          </Button2>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export const RenameFolderDialog = ({
  folder,
  children,
  onOpenChange,
}: {
  folder: TAssetLibraryFolder
  children?: ReactNode
  onOpenChange?: (open: boolean) => void
}) => {
  const { success, error } = useNotifications()
  const { updateFolder } = useMutateAssetLibraryFolder()

  const [newFolderName, setNewFolderName] = useState(folder.name)

  const rename = useCallback(async () => {
    if (!newFolderName) {
      return
    }
    try {
      onOpenChange?.(false)
      await updateFolder({
        id: folder.id,
        name: newFolderName.trim(),
      })
      success('Folder renamed successfully')
    } catch (e) {
      console.error(e)
      error('Failed to rename folder')
    }
  }, [newFolderName, onOpenChange, updateFolder, folder.id, success, error])

  return (
    <Dialog onOpenChange={onOpenChange}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>Rename Folder</DialogTitle>
        </DialogHeader>

        <form
          onSubmit={(e) => {
            e.preventDefault()
            rename()
          }}
        >
          <div className='flex flex-col gap-2'>
            <label htmlFor='folderName' className={'text-xs text-grey-s5'} autoFocus={false}>
              Folder Name
            </label>
            <Input2
              id='folderName'
              type='text'
              name='folderName'
              value={newFolderName}
              onInput={(e) => setNewFolderName(e.currentTarget.value)}
            />
          </div>
        </form>

        <div className={'flex justify-end gap-3'}>
          <Button2
            variant={'plain'}
            onClick={() => {
              onOpenChange?.(false)
              setNewFolderName(folder.name)
            }}
          >
            Cancel
          </Button2>
          <Button2
            type={'submit'}
            disabled={!newFolderName}
            leadingIcon={'checkmark'}
            variant={'solid'}
            onClick={rename}
          >
            Rename Folder
          </Button2>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export const DeleteFolderDialog = ({
  folderId,
  children,
  onOpenChange,
}: {
  folderId: string
  children?: ReactNode
  onOpenChange?: (open: boolean) => void
}) => {
  const { success, error } = useNotifications()
  const { deleteFolder } = useMutateAssetLibraryFolder()

  return (
    <AlertDialog onOpenChange={onOpenChange}>
      <AlertDialogTrigger>{children}</AlertDialogTrigger>
      <AlertDialogContent onClick={(e) => e.stopPropagation()}>
        <AlertDialogHeader iconName='trash'>Delete Folder?</AlertDialogHeader>
        <AlertDialogDescription>
          Are you sure you want to delete this folder? Please note that all included assets will
          also be permanently deleted.
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            leadingIcon={'trash'}
            onClick={async () => {
              try {
                await deleteFolder(folderId)
                success('Folder deleted successfully')
              } catch (e) {
                console.error(e)
                error('Failed to delete folder')
              }
            }}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
