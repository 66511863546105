import { useMutation, useQueryClient } from '@tanstack/react-query'
import { spacesApi } from '../../api/spacesApi/spacesAPIclient'
import { TCustomProperty } from '@valuecase/common'

export type TUpsertCustomPropertyData = Pick<
  TCustomProperty,
  'displayName' | 'key' | 'gptPrompt' | 'options'
> & {
  id?: string
}

type Data = {
  tenantId: string
  customProperty: TUpsertCustomPropertyData
}

export async function upsertCustomPropertyFn(data: Data) {
  const { id, key, ...payload } = data.customProperty
  const isCreate = !id
  if (isCreate) {
    return await spacesApi.post(`/tenants/${data.tenantId}/variables`, data.customProperty)
  } else {
    return await spacesApi.patch(
      `/tenants/${data.tenantId}/variables/${data.customProperty.id}`,
      payload,
    )
  }
}

export function useUpsertCustomProperty(onSuccess: () => void) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: upsertCustomPropertyFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tenant-custom-properties'] })
      if (onSuccess) onSuccess()
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    upsertCustomProperty: (data: Data) => mutation.mutate(data),
  }
}
