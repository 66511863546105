import { FC } from 'react'
import { TemplateCreatorTemplateListProps } from '../types/TemplateCreatorTemplateListProps'
import { SelectList } from '../../../ui-components/SelectList'

export const TemplateCreatorLibrary: FC<TemplateCreatorTemplateListProps> = ({
  templateList,
  selectTemplate,
  selectedTemplateId,
}) => {
  return (
    <>
      <SelectList
        items={[
          {
            id: templateList[0].id, //this is the empty template placeholder
            text: 'Start from blank',
            icon: {
              source: 'emoji-string',
              emojiString: '🗒️',
            },
          },
        ]}
        selectItem={selectTemplate}
        selectedId={selectedTemplateId}
      />
      <div style={{ fontSize: '12px' }} className='ml-6 mt-4 font-semibold text-xs text-grey-s4'>
        Template Library
      </div>
      {/* bellow we render the templates library */}
      <SelectList
        items={templateList.slice(1).map((template) => {
          return {
            id: template.id,
            text: template.title,
            icon: template.icon
              ? {
                  source: 'emoji-string',
                  // TODO fix types
                  emojiString: template.icon as unknown as string,
                }
              : {
                  source: 'emoji-string',
                  emojiString: '🚀',
                },
          }
        })}
        selectItem={selectTemplate}
        selectedId={selectedTemplateId}
      />
    </>
  )
}
